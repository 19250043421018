import { PilotAutoTop } from './types';

export const jaTop: PilotAutoTop = {
  overview: {
    description:
      'Pilot.Auto は、オープンソースの自動運転オペレーティングシステム「Autoware」をベースとした、拡張可能な自動運転システムを実現できるプラットフォームです。オープンな技術コンポーネントを組み合わせることにより、様々な環境に対してレベル4相当の自動運転を可能にするプラットフォームで、走行するほど賢くなり、運行設計領域 (ODD) を安全に拡張できます。さらに、ティアフォーが提供する5つのReference Design (参照設計) を、Pilot.Auto上で利用、拡張することにより、ユーザーの要求に合った自動運転システムの構築や開発ができます。また、自動運転に不可欠な地図・データ・シミュレーション等を含むDevOpsプラットフォーム「Web.Auto」とPilot.Autoを連携すると、自動運転の開発や運用サービスをすぐにでも開始できます。',
  },
  reference_design: {
    list: {
      description:
        'ユーザーの考えるビジネスの用途に合うReference Designを選択します。Reference Designは左記で説明している要素で構成されており、ユーザーの要望に合わせて拡張が可能な設計になっています。',
      business_model: {
        list: {
          item1: '市場ニーズ',
          item2: 'ターゲットユーザー',
          item3: '電力 / パフォーマンス',
          item4: 'コスト',
          item5: '場所',
          item6: 'その他',
        },
      },
      concept: {
        list: {
          item1: 'ODD',
          item2: 'ユーザーおよびサービス',
        },
      },
      use_cases: {
        list: {
          item1: 'シナリオ',
        },
      },
      feature: {
        list: {
          item1: '機能要求',
          item2: '振る舞い',
        },
      },
      system_requirements: {
        list: {
          item1: 'システム構成',
          item2: 'ハードウェア要求',
          item3: 'ソフトウェア要求',
        },
      },
      customers_products: {
        list: {
          item1: 'ベースとなるReference Design',
          item2: '追加されたユースケース',
          item3: '追加されたフィーチャー',
          item4: '追加された要求',
        },
      },
    },
  },
  odd: {
    classification: {
      description:
        'Operational Design Domain (ODD)とは、⾃動運転システムが安全に作動するための前提条件です。あらかじめ定められたODDの範囲内でのみ⾃動運転システムが作動するように設計することで、⾛⾏時の安全性を担保しています。ティアフォーでは、事業者およびビジネスモデルによる違いも加味しつつ、これまでの経験を基に⾃動運転の技術的難易度に応じてODDを3つに類型化しています。',
      odd1: {
        description:
          '自動運転専用の閉鎖環境。歩行者や他の車両がいても、常に自動運転車両が優先される。低速走行は規制されている。工場やキャンパスなどは、このような環境に分類。',
        point1: {
          title: '自動運転車専用環境',
          item1: 'テスト走行',
          item2: '研究機関におけるAD',
        },
        point2: {
          title: '工場内の物流道路',
          item1: 'ヤマハの製造工場内の部品搬送（台車）',
        },
        point3: {
          title: '施設内の循環ルート',
          item1: 'モリコロパーク内の循環線バス（小型EV）',
          item2: '清水建設工場内施設間自律移動（小型EV）',
          item3: 'オリンピック村のループラインバス（バス）',
        },
      },
      odd2: {
        description:
          'ステークホルダーが管理するジオフェンス環境。歩行者や他の車両が自動運転車両と共存するが、走行速度は制限され緩やかである。山間地域や過疎地等のエリアはこのような環境に分類。',
        point1: {
          title: '山間部',
          item1: '山口県宇部市などの山間部でのワンマン輸送（乗用車）',
          item2: '愛知県飛島村の地域交通（乗用車）',
          item3: '日間賀島での観光型MaaS（バス）',
        },
      },
      odd3: {
        description:
          'オープンな環境でありながら、規制によって構成されている。歩行者や他の車両と自動走行車両が共存し、規制の範囲内であれば走行速度が制限されない。首都圏や高速道路などがこれに該当。',
        point1: {
          title: '郊外での循環型路線',
          item1: '埼玉県坂戸市の一般道(バス)',
          item2: '東京臨海部の地方道（乗用車）',
        },
        point2: {
          title: '都市部',
          item1: '新宿区（乗用車）',
        },
      },
    },
    reference_design: {
      description:
        'Delivery Robot、Cargo Transport、Shuttle Bus、Robo-TaxiそしてPersonal Carの5つのReference Designが存在しており、ODDを定義するユースケースとシナリオをベースに、これらのReference Designを開発しています。',
      delivery_robot: {
        description: '小型物流搬送ロボット',
        links: {
          reference_design: '',
        },
      },
      cargo_transport: {
        description: '工場内/私有地物流搬送車両',
        links: {
          reference_design: '',
        },
      },
      shuttle_bus: {
        description: '低速旅客搬送車両',
        links: {
          reference_design: '',
        },
      },
      robo_taxi: {
        description: 'ロボットタクシー車両',
        links: {
          reference_design: '',
        },
      },
      personal_car: {
        description: '自家用自動運転車両',
      },
    },
  },
};
