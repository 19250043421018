import { mq } from '@/breakpoints';
import Button from '@/components/common/Button';
import { useLangPath } from '@/i18n';
import { ReleaseNote } from '@/services/documents';
import { getBranchLink } from '@/util/branch';
import { getContentsImagePath } from '@/util/contetsImagePath';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

type Props = {
  releaseNotes: ReleaseNote[];
};

const Update: React.FC<Props> = ({ releaseNotes }: Props) => {
  const langPath = useLangPath();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Left>
        <Title>UPDATE</Title>
        <Button isLink href={`${langPath}release-notes/`}>
          RELEASE NOTES
        </Button>
      </Left>
      <Right>
        <UpdateList>
          {releaseNotes.map((note) => {
            const img = getContentsImagePath(note.thumbnail);
            return (
              <UpdateItem key={note.slug}>
                <Link to={getBranchLink(note.slug.replace('/ja/', '/'))}>
                  <UpdateInfo>
                    <InfoTitle>{note.title}</InfoTitle>
                    <InfoDescription>{note.description}</InfoDescription>
                    <InfoDate>
                      {DateTime.fromISO(note.published_at).toFormat(
                        t('date.format'),
                      )}
                    </InfoDate>
                  </UpdateInfo>
                  <UpdateImageWrapper
                    css={css`
                      background-image: url(${img});
                    `}
                  />
                </Link>
              </UpdateItem>
            );
          })}
        </UpdateList>
      </Right>
    </Wrapper>
  );
};

export default Update;

const Wrapper = styled.div`
  position: relative;
  margin-top: -60px;
  background-color: #fff;
  border-radius: 80px 80px 0 0;
  display: flex;
  font-family: 'Inter';
  align-items: stretch;

  ${mq.md} {
    border-radius: 40px 40px 0 0;
    margin-top: -30px;
    display: block;
  }
`;

const Left = styled.div`
  width: 520px;
  background-color: #f0f4ff;
  box-sizing: border-box;
  padding: 322px 0 0 120px;
  border-radius: 80px 24px 0 0;

  ${mq.md} {
    width: auto;
    margin: 0 30px;
    background-color: transparent;
    border-radius: 0;
    padding: 160px 0 0;
  }
`;

const Title = styled.h2`
  font-size: 48px;
  color: #1f2933;
  line-height: 1.2;
  margin: 0 0 80px;

  ${mq.md} {
    font-size: 40px;
    margin: 0 0 40px;
  }
`;

const Right = styled.div`
  flex: 1;
  padding: 200px 0;

  ${mq.md} {
    padding: 40px 0;
  }
`;

const UpdateList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 120px;

  ${mq.md} {
    margin: 0 30px;
  }
`;

const UpdateInfo = styled.div`
  flex: 1;
  padding-right: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InfoTitle = styled.p`
  color: #1f2933;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 12px;
`;

const InfoDescription = styled.p`
  color: #626c76;
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
`;

const InfoDate = styled.p`
  font-size: 10px;
  line-height: 1.5;
  color: #626c76;
`;

const UpdateImageWrapper = styled.div`
  width: 200px;
  padding: 40px 0;
  border-radius: 16px;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  ${mq.md} {
    padding: 80px 0;
  }
`;

const UpdateItem = styled.li`
  border-top: solid 1px rgba(48, 152, 166, 0.16);

  &:last-of-type {
    border-bottom: solid 1px rgba(48, 152, 166, 0.16);
  }

  a {
    display: flex;
    padding: 48px 0;
    justify-content: space-between;
    text-decoration: none;

    ${mq.md} {
      flex-direction: row-reverse;
    }
  }

  ${mq.md} {
    &:first-of-type {
      a {
        flex-direction: column-reverse;
      }

      ${UpdateInfo} {
        padding: 16px;
      }

      ${UpdateImageWrapper} {
        width: 100%;
        padding: 80px 0;
      }

      ${InfoDate} {
        margin-top: 1em;
      }
    }

    ${UpdateInfo} {
      padding: 0 0 0 24px;
    }

    ${UpdateImageWrapper} {
      width: 30%;
      padding: 40px 0;
      margin-right: 20px;
    }
  }
`;
