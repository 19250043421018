import styled from '@emotion/styled';
import { mq } from '@/breakpoints';
import Button from '@/components/common/Button';
import Helmet from '@/components/common/Helmet';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { savePath } from '@/services/t4account/user';
import { signInRedirect } from '@/util/authenticationManager';
import { executeWhenVisible } from '@tier4/webauto-auth-frontend-client';

const UnAuthentication: React.FC = () => {
  const { t } = useTranslation();

  const handleClickLogin = useCallback(async () => {
    savePath();
    await executeWhenVisible(signInRedirect);
  }, []);

  return (
    <>
      <Helmet title="Unauthentication" />
      <Wrapper>
        <Contents>
          <p>{t('entry.error.unauthentication')}</p>
          <Button onClick={handleClickLogin}>Login</Button>
        </Contents>
      </Wrapper>
    </>
  );
};

export default UnAuthentication;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Contents = styled.div`
  text-align: center;

  p {
    margin: 0 0 1em;
    line-height: 1;
    font-family: 'Inter';
    font-size: 26px;
    font-weight: 600;
  }

  button {
    margin: 0 auto;
  }

  ${mq.md} {
    p {
      font-size: 24px;
    }
  }
`;
