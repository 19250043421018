import { WebAutoTop } from './types';

export const jaTop: WebAutoTop = {
  top: {
    description:
      "Web.AutoはクラウドネイティブなDevOpsプラットフォームです。IoT・クラウド技術を使って自動運転システムの開発、構築、利用、運用の全てをサポートします。サービス事業者にはOperation Platform(OP)を用いることで、サービスの運行管理及びデータの利活用ができ、開発者は開発基盤であるDevelopment Platform(DP)を用いることで、自動運転システムの開発、構築を加速する事ができます。ここではそのWeb.AutoのUser Manuals及び開発のためのDeveloper's Guideを提供します。",
  },
  how_to_start: {
    description:
      'TIER IV アカウントを作成することで、Web.Autoを利用することができます。詳しくは、以下のリンクからご確認ください。',
  },
};
