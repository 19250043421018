import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, LinkProps, matchPath, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { mq } from '@/breakpoints';
import { drawerOpenAtom } from '@/global-states/drawer';
import { useLangPath } from '@/i18n';
import { getBranchLink } from '@/util/branch';
import { savePath, userAtom } from '@/services/t4account/user';
import store from 'store2';
import throttle from 'lodash.throttle';
import {
  authenticationManager,
  signInRedirect,
} from '@/util/authenticationManager';
import { executeWhenVisible } from '@tier4/webauto-auth-frontend-client';
import {
  isEnableAuth,
  isPilotAuto,
  isWebAuto,
  siteType,
} from '@/util/appConfig';
import { siteTtitle } from '@/util/appConfig';

const Header: React.FC = () => {
  const langPath = useLangPath();
  const setDrawerOpen = useSetRecoilState(drawerOpenAtom);
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [accountMenu, setAccountMenu] = useState<React.ReactNode>();
  const user = useRecoilValue(userAtom);

  const isCurrnt = useCallback(
    (pathname: string) => {
      const splitPath = location.pathname.split('/');
      const pageId = splitPath[1] === 'en' ? splitPath[2] : splitPath[1];
      return pathname === pageId ? 1 : 0;
    },
    [location.pathname],
  );

  /**
   * Web.Auto のみに表示するメニュー
   */
  const webAutoMenu = useMemo(() => {
    if (!isWebAuto) return;
    return (
      <>
        <li>
          <MenuLink
            current={isCurrnt('user-manuals')}
            to={getBranchLink(`${langPath}user-manuals/`)}
          >
            USER MANUALS
          </MenuLink>
        </li>
        <li>
          <MenuLink
            current={isCurrnt('developers-guides')}
            to={getBranchLink(`${langPath}developers-guides/`)}
          >
            DEVELOPER&apos;S GUIDES
          </MenuLink>
        </li>
      </>
    );
  }, [isCurrnt, langPath]);

  /**
   * Pilot.Auto のみに表示するメニュー
   */
  const pilotAutoMenu = useMemo(() => {
    if (!isPilotAuto) return;
    return (
      <>
        <li>
          <MenuLink
            current={isCurrnt('reference-design')}
            to={getBranchLink(`${langPath}reference-design/`)}
          >
            REFERENCE DESIGN
          </MenuLink>
        </li>
      </>
    );
  }, [isCurrnt, langPath]);

  const isTop = useMemo(() => {
    return matchPath(location.pathname, `${langPath}`) ? 1 : 0;
  }, [langPath, location.pathname]);

  const handleClickLogin = useCallback(async () => {
    savePath();
    await executeWhenVisible(signInRedirect);
  }, []);

  const handleClickLogout = useCallback(async () => {
    store.session.remove('webauto_auth_js_bearer');
    await authenticationManager.userManager.signOutRedirect();
  }, []);

  const handleClickNav = useCallback(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  useEffect(() => {
    if (!isEnableAuth) return;
    (async () => {
      try {
        if (user) {
          setAccountMenu(
            <li onClick={handleClickLogout}>
              <img src="/assets/common/header/logout.svg" />
            </li>,
          );
          return;
        }
        setAccountMenu(
          <li onClick={handleClickLogin}>
            <img src="/assets/common/header/login.svg" />
          </li>,
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, [handleClickLogin, handleClickLogout, user]);

  useEffect(() => {
    const handleScrollWindow = () => {
      let scrollTop = document.body.scrollTop;
      if (document.scrollingElement) {
        scrollTop = document.scrollingElement.scrollTop;
      }
      //  FIXME: Throttle に入れたいが react18 で react-use の useThrottle が機能していないので後々修正
      //  https://github.com/streamich/react-use/issues/2343
      if (scrollTop >= 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const throttled = throttle(handleScrollWindow, 100);
    window.addEventListener('scroll', throttled);
    return () => window.removeEventListener('scroll', throttled);
  }, []);

  return (
    <Wrapper isTop={isTop} isScrolled={isScrolled ? 1 : 0} data-id="header">
      <LogoWrapper>
        <Link to={getBranchLink(langPath)}>
          <img src={`/assets/${siteType}/common/logo.svg`} alt={siteTtitle} />
        </Link>
      </LogoWrapper>
      <CenterNavWrapper>
        <ul>
          {webAutoMenu}
          {pilotAutoMenu}
          <li>
            <MenuLink
              current={isCurrnt('release-notes')}
              to={getBranchLink(`${langPath}release-notes/`)}
            >
              RELEASE NOTES
            </MenuLink>
          </li>
          <li>
            <MenuLink
              current={isCurrnt('faqs')}
              to={getBranchLink(`${langPath}faqs/`)}
            >
              FAQ &amp; CONTACTS
            </MenuLink>
          </li>
        </ul>
      </CenterNavWrapper>
      <RightNavWrapper>
        <ul>
          {accountMenu}
          <li onClick={handleClickNav}>
            <img src="/assets/common/header/menu.svg" />
          </li>
        </ul>
      </RightNavWrapper>
    </Wrapper>
  );
};

export default Header;

type WrapperProps = {
  isTop: 0 | 1;
  isScrolled: 0 | 1;
};

const Wrapper = styled.header<WrapperProps>`
  padding: 0 0 0 48px;
  height: 68px;
  background-color: white;
  box-shadow: 0px 2px 16px rgba(48, 152, 166, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: height 200ms;

  ${({ isTop }) => {
    if (isTop) {
      return css`
        box-shadow: none;
        position: fixed;
        width: 100%;

        body[data-type='pilotauto'] & {
          background-color: #f0f4ff;

          ${mq.md} {
            background-color: #fff;
          }
        }

        ${mq.md} {
          box-shadow: 0px 2px 16px rgba(48, 152, 166, 0.16);
        }
      `;
    }
  }}

  ${({ isTop, isScrolled }) => {
    if (isTop) {
      if (isScrolled) {
        return css`
          height: 68px;
        `;
      }

      return css`
        height: 108px;
      `;
    }
  }}

  ${mq.md} {
    height: 58px;
    padding: 0 0 0 20px;
    position: static;
  }

  img {
    width: 91px;
    height: 14px;
  }

  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
`;

const LogoWrapper = styled.h1`
  margin: 0;

  ${mq.md} {
    width: 78px;
    height: 100%;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    svg {
      width: 100%;
    }
  }

  img {
    display: block;
  }
`;

const CenterNavWrapper = styled.nav`
  display: flex;
  align-items: center;

  ${mq.md} {
    display: none;
  }

  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;

    li {
      font-size: 12px;
    }
  }
`;

const RightNavWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  ${mq.md} {
    height: 100%;
  }

  ul {
    text-align: flex-end;

    ${mq.md} {
      height: 100%;
    }

    li {
      cursor: pointer;
      padding: 0px 32px;
      box-sizing: border-box;
      border-left: solid 1px rgba(48, 152, 166, 0.16);

      img {
        width: 20px;
        height: 20px;
      }

      &:last-of-type {
        img {
          width: 18px;
          height: 14px;
        }
      }

      ${mq.md} {
        width: 90px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

type MenuLinkProps = {
  current: 0 | 1;
} & LinkProps;

const MenuLink = styled(Link)<MenuLinkProps>`
  color: ${({ theme }) => theme.textColor.primary};
  text-decoration: none;
  padding: 16px 20px;
  position: relative;
  transition: color 300ms;

  &:hover {
    color: #3098a6;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 2px;
    left: calc(50% - 2px);
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #3098a6;
    transition: transform 300ms;
    transform: scale(0);
  }

  ${({ current }) => {
    if (current) {
      return css`
        color: #3098a6;

        &::after {
          transform: scale(1);
        }
      `;
    }
  }}
`;
