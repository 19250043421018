import { PilotAutoTop } from './types';

export const enTop: PilotAutoTop = {
  overview: {
    description:
      "Pilot.Auto is a platform for scalable autonomous driving systems based on Autoware, the open-source autonomous driving operating system. The platform enables Level 4 autonomous driving for a variety of environments by combining open-technology components. The autonomous driving system becomes smarter as it drives and safely scales its Operational Design Domain (ODD). In addition, TIER IV's five reference designs can be used and scaled through Pilot.Auto to build and develop autonomous driving systems that meet our partner's requirements. Moreover, our partners can start developing autonomous driving systems and operating the service by using Pilot.Auto with Web.Auto, is a DevOps platform that includes essential applications, maps, data, simulations, and so on for developing autonomous driving systems.",
  },
  reference_design: {
    list: {
      description:
        'Partners can choose the Reference Design that best fits the application of their business model, consisting of the elements described on the left, and then scale and adapt that Reference Design to meet their needs.',
      business_model: {
        list: {
          item1: 'Market Needs',
          item2: 'Target User',
          item3: 'Power / Performance',
          item4: 'Cost',
          item5: 'Region',
          item6: 'etc',
        },
      },
      concept: {
        list: {
          item1: 'Operational Design Domain',
          item2: 'Users and Services',
        },
      },
      use_cases: {
        list: {
          item1: 'Scenarios',
        },
      },
      feature: {
        list: {
          item1: 'Functionality',
          item2: 'Behavior',
        },
      },
      system_requirements: {
        list: {
          item1: 'System Configuration',
          item2: 'Hardware Requirements',
          item3: 'Software Requirements',
        },
      },
      customers_products: {
        list: {
          item1: 'Baseline Reference Design',
          item2: 'Additional Use Cases',
          item3: 'Additional Feature',
          item4: 'Additional Requirements',
        },
      },
    },
  },
  odd: {
    classification: {
      description:
        'An Operational Design Domain (ODD) is a prerequisite for the safe operation of autonomous driving systems. We ensure safety by designing our autonomous driving system which operates only within the predetermined ODD. Based on our extensive experience of autonomous vehicle development, TIER IV has classified ODDs into three categories according to the technical difficulty of autonomous driving, while taking into account differences between service operators and their business models.',
      odd1: {
        description:
          'Closed environments dedicated to autonomous vehicles. Pedestrians and other vehicles may be present but autonomous vehicles are always prioritized. Low-speed operation is regulated. Factories and campuses are categorized into these environments.',
        point1: {
          title: 'AD dedicated environment',
          item1: 'Test driving',
          item2: 'AD in research institutes',
        },
        point2: {
          title: 'Logistics roads inside factories',
          item1:
            "Component transportation within Yamaha's manufacturing plant1 (cart) ",
        },
        point3: {
          title: 'Cyclic routes inside facilities',
          item1: 'A loop line bus in Moricoro Park (small-sized EV)',
          item2:
            "Facility to facility autonomous transportation in Shimizu-Kensetsu's factory (small-sized EV)",
          item3: 'A loop line bus at the Olympic Village (bus)',
        },
      },
      odd2: {
        description:
          'Geofenced environments managed by stakeholders. Pedestrians and other vehicles co-exist with autonomous vehicles, but the driving speed is restricted and moderate. Local cities are categorized into these environments.',
        point1: {
          title: 'Mountain areas',
          item1:
            'One-mile transportation in mountain areas such as Ube-shi, Yamaguchi (passenger car)',
          item2:
            'Regional transportation at Tobishima-mura, Aichi (passenger car)',
          item3: 'Tourism-oriented MaaS in Himaka-jima (bus)',
        },
      },
      odd3: {
        description:
          'Open environments yet structured by regulations. Pedestrians and other vehicles co-exist with autonomous vehicles, and the driving speed is unrestricted in the range of regulations. Capital cities and highways/freeways are categorized into these environments.',
        point1: {
          title: 'Cyclic routes in suburban areas',
          item1: 'Public roads in Sakado-shi, Saitama (bus)',
          item2: 'Local roads in Tokyo coastal area (passenger car)',
        },
        point2: {
          title: 'Urban areas',
          item1: 'Shinjuku city area (passenger car)',
        },
      },
    },
    reference_design: {
      description:
        'We develop multiple reference designs of autonomous driving systems, Delivery Robot, Cargo Transport, Shuttle Bus, Robo-Taxi and Personal Car in the form of use cases and scenarios defining ODDs, but not in the form of software or hardware.',
      delivery_robot: {
        description:
          'AMR(autonomous mobile robot) as micro Delivery for one-mile goods transportation.',
        links: {
          reference_design: '',
        },
      },
      cargo_transport: {
        description:
          'Transportation vehicle in logistics / cyclic roads inside private property.',
        links: {
          reference_design: '',
        },
      },
      shuttle_bus: {
        description:
          'V1 / Passenger transporation as MaaS for private property.\nV2 / Passenger transporation as Maas for public road.',
        links: {
          reference_design: '',
        },
      },
      robo_taxi: {
        description: 'For Taxi Services',
        links: {
          reference_design: '',
        },
      },
      personal_car: {
        description: 'For privately owned vehicles (WIP)',
      },
    },
  },
};
