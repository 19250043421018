import { Helmet as ReactHelmet } from 'react-helmet-async';
import { useMemo } from 'react';
import { siteDescription, siteTtitle } from '@/util/appConfig';

type Props = {
  title?: string;
  description?: string;
  image?: string;
};

const Helmet: React.FC<Props> = ({ title, description, image }: Props) => {
  const pageTitle = useMemo(
    () => (title ? `${title} | ${siteTtitle}` : siteTtitle),
    [title],
  );

  const pageDescription = useMemo(
    () => description ?? siteDescription,
    [description],
  );

  return (
    <ReactHelmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      <meta name="description" content={pageDescription} />
      <meta property="og:description" content={pageDescription} />
      {image && <meta property="og:image" content={image} />}
    </ReactHelmet>
  );
};

export default Helmet;
