import { useCallback, useState } from 'react';
import { EntryData } from './types';
import { addEndSlash } from '@/util/endSlash';
import { AxiosError } from 'axios';
import { documentAPI } from '..';
export * from './types';

export const useEntry = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getEntry = useCallback(async (path: string): Promise<EntryData> => {
    setIsLoading(true);

    let entryData: EntryData = {
      ja: null,
      en: null,
      is401: false,
      is403: false,
    };

    try {
      const jaEntryRes = await documentAPI.get(
        `/ja/${addEndSlash(path)}index.json`,
      );
      entryData = {
        ...entryData,
        ja: jaEntryRes.data,
      };
    } catch (err) {
      console.log(err);
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          entryData = {
            ...entryData,
            is401: true,
          };
        }
        if (err.response?.status === 403) {
          entryData = {
            ...entryData,
            is403: true,
          };
        }
      }
    }

    try {
      const enEntryRes = await documentAPI.get(
        `en/${addEndSlash(path)}index.json`,
      );
      entryData = {
        ...entryData,
        en: enEntryRes.data,
      };
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);

    return entryData;
  }, []);

  return {
    isLoading,
    getEntry,
  };
};
