import styled from '@emotion/styled';
import { mq } from '@/breakpoints';
import Button from '@/components/common/Button';
import Helmet from '@/components/common/Helmet';
import { useLangPath } from '@/i18n';

const NotFound: React.FC = () => {
  const langPath = useLangPath();

  return (
    <>
      <Helmet title="Page Not Found" />
      <Wrapper>
        <Contents>
          <p>Page not found.</p>
          <Button isLink href={langPath}>
            TOP
          </Button>
        </Contents>
      </Wrapper>
    </>
  );
};

export default NotFound;

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 68px - 120px);
  display: flex;
  justify-content: center;
  align-items: center;

  ${mq.md} {
    height: calc(100vh - 58px - 238px);
  }
`;

const Contents = styled.div`
  text-align: center;

  p {
    margin: 0 0 1em;
    line-height: 1;
    font-family: 'Inter';
    font-size: 42px;
    font-weight: 600;
  }

  a {
    display: inline-block;
  }

  ${mq.md} {
    p {
      font-size: 24px;
    }
  }
`;
