import { mq } from '@/breakpoints';
import type { Navigation, NavigationItem } from '@/services/documents';
import { getBranchLink } from '@/util/branch';
import { removeEndSlash } from '@/util/endSlash';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type OrderNavigationLinkProps = {
  item: NavigationItem | null;
  type: 'previous' | 'next';
};

const OrderNavigationLink: React.FC<OrderNavigationLinkProps> = ({
  item,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <ItemWrapper item={item} type={type}>
      <Link to={getBranchLink(item?.link ?? '')}>
        <span className="type">{t(`entry.order_navigation.${type}`)}</span>
        <span className="name">{item?.name}</span>
      </Link>
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div<OrderNavigationLinkProps>`
  width: 50%;

  &:first-of-type {
    padding-right: 12px;
  }

  &:last-of-type {
    padding-left: 12px;
  }

  ${({ item }) => {
    if (!item) {
      return css`
        visibility: hidden;
        pointer-events: none;
      `;
    }
  }}

  a {
    text-decoration: none;
    text-align: ${({ type }) => (type === 'previous' ? 'left' : 'right')};

    span {
      display: block;

      &.type {
        color: ${({ theme }) => theme.textColor.primary};
        font-weight: normal;
        font-size: 14px;
        line-height: 1;
        margin-bottom: 1em;

        ${mq.md} {
          font-size: 13px;
        }
      }

      &.name {
        color: #3098a6;
        font-weight: 700;
        font-size: 16px;

        ${mq.md} {
          font-size: 14px;
        }
      }
    }

    &:hover {
      span {
        &.name {
          text-decoration: underline;
        }
      }
    }
  }
`;

type Props = {
  navigation: Navigation;
};

const OrderNavigation: React.FC<Props> = ({ navigation }) => {
  const location = useLocation();

  const currentPagePath = useMemo(() => location.pathname, [location.pathname]);

  const allLinkItems = useMemo(() => {
    const arr: NavigationItem[] = [];
    const setItems = (items: NavigationItem[]) => {
      items.forEach((item) => {
        if (item.link) arr.push(item);
        if (item.navigation?.items) {
          setItems(item.navigation.items);
        }
      });
    };
    setItems(navigation.items);
    return arr;
  }, [navigation]);

  const currentPageIndex = useMemo(
    () =>
      allLinkItems.findIndex(
        (item) => removeEndSlash(currentPagePath) === item.link,
      ),
    [allLinkItems, currentPagePath],
  );

  const previousNavigationItem = useMemo(
    () => (currentPageIndex <= 0 ? null : allLinkItems[currentPageIndex - 1]),
    [currentPageIndex, allLinkItems],
  );

  const nextNavigationItem = useMemo(
    () =>
      currentPageIndex < 0 || currentPageIndex === allLinkItems.length - 1
        ? null
        : allLinkItems[currentPageIndex + 1],
    [currentPageIndex, allLinkItems],
  );

  return (
    <Wrapper>
      <OrderNavigationLink type="previous" item={previousNavigationItem} />
      <OrderNavigationLink type="next" item={nextNavigationItem} />
    </Wrapper>
  );
};

export default OrderNavigation;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  ${mq.md} {
    margin-top: 30px;
  }
`;
