import { mq } from '@/breakpoints';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { IndexContentsWrapper } from '../styled';

const Overview: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <IndexContentsWrapper>
        <Section>
          <Title>OVERVIEW</Title>
          <OverviewWrapper>
            <OverviewTitle>WHAT IS Pilot.Auto ?</OverviewTitle>
            <OverviewDescription>
              {t('pilotauto.top.overview.description')}
            </OverviewDescription>
            <OverviewLinks>
              <a href="https://pilot.auto" target="_blank" rel="noreferrer">
                View Pilot.Auto
              </a>
              <a href="https://web.auto/" target="_blank" rel="noreferrer">
                View Web.Auto
              </a>
            </OverviewLinks>
          </OverviewWrapper>
        </Section>
      </IndexContentsWrapper>
    </Wrapper>
  );
};

export default Overview;

const Wrapper = styled.div`
  background-color: #fff;
  padding: 200px 0;
  position: relative;

  ${mq.md} {
    padding: 160px 0;

    ${IndexContentsWrapper} {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
`;

const Section = styled.section`
  display: flex;
  justify-content: space-between;

  ${mq.md} {
    display: block;
  }
`;

const Title = styled.h2`
  line-height: 1.2;
  font-size: 48px;
  font-weight: 600;
  margin: 0 40px 0 0;

  ${mq.md} {
    font-size: 40px;
    margin-bottom: 48px;
  }
`;

const OverviewWrapper = styled.div`
  width: 600px;
  font-family: 'Inter';
  padding-top: 2px;

  ${mq.md} {
    width: 100%;
  }
`;

const OverviewTitle = styled.h3`
  margin: 0 0 32px;
  line-height: 1.4;
  font-size: 24px;

  ${mq.md} {
    font-size: 18px;
    margin: 0 0 24px;
  }
`;

const OverviewDescription = styled.p`
  font-size: 14px;

  ${mq.md} {
    font-size: 12px;
  }
`;

const OverviewLinks = styled.div`
  display: flex;
  margin-top: 40px;

  ${mq.md} {
    display: block;
  }

  a {
    color: #1f2933;
    font-size: 14px;
    text-decoration: none;
    margin-right: 40px;

    &::before {
      width: 12px;
      height: 12px;
      content: '';
      display: inline-block;
      background-image: url(/assets/common/common/icon_arrow_right_green.svg);
      transform: rotate(-45deg);
      margin-right: 6px;
      vertical-align: middle;
      transition: transform 200ms ease-out;
    }

    &:hover {
      &::before {
        transform: rotate(0);
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    ${mq.md} {
      display: block;
      margin-right: 0;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
