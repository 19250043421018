import styled from '@emotion/styled';
import React from 'react';
import WebAutoIndex from '@/components/webauto/Index';
import PilotAutoIndex from '@/components/pilotauto/Index';
import { mq } from '@/breakpoints';
import { isWebAuto } from '@/util/appConfig';

const Index: React.FC = () => (
  <Wrapper>{isWebAuto ? <WebAutoIndex /> : <PilotAutoIndex />}</Wrapper>
);

export default Index;

const Wrapper = styled.div`
  font-family: futura-pt, noto-sans-cjk-jp, sans-serif;
  margin-top: 108px;

  ${mq.md} {
    margin-top: 0;
  }
`;
