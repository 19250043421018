import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import App from '@/App';
import theme from '@/theme';
import { i18nInit } from '@/i18n';
import 'sanitize.css';
import 'prism-themes/themes/prism-dracula.css';

i18nInit();

const rootElement = document.getElementById('root');

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <HelmetProvider>
        <RecoilRoot>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </BrowserRouter>
        </RecoilRoot>
      </HelmetProvider>
    </React.StrictMode>,
  );
}
