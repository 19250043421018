import { mq } from '@/breakpoints';
import styled from '@emotion/styled';

export const IndexContentsWrapper = styled.div`
  margin: 0 120px;
  position: relative;

  ${mq.md} {
    margin: 0 20px;
  }
`;

export const IndexImageWrapper = styled.div`
  background-position: center;
  background-size: cover;

  ${mq.md} {
    padding-top: 100% !important;
  }

  &.top {
    background-image: url(/assets/webauto/index/image_1.jpg);
    padding-top: 50%;

    ${mq.md} {
      background-image: url(/assets/webauto/index/image_1_sp.jpg);
    }
  }

  &.product {
    background-image: url(/assets/webauto/index/image_2.jpg);
    padding-top: 47%;

    ${mq.md} {
      background-image: url(/assets/webauto/index/image_2_sp.jpg);
    }
  }
`;

////////////////////////////////////////////////
// User Manuals & Developer's Guide
////////////////////////////////////////////////

export const GuideTitle = styled.h2`
  font-weight: 600;
  font-size: 120px;
  margin: 0;
  line-height: 0.9;

  ${mq.md} {
    font-size: 40px;
    line-height: 1;
  }
`;

export const GuideUpdatesWrapper = styled.div`
  display: flex;
  margin-top: 160px;

  ${mq.md} {
    display: block;
    margin-top: 64px;
  }
`;

export const GuideUpdatesMenuWrapper = styled.div`
  box-sizing: border-box;
  padding: 120px;
  background-color: #f8f9fc;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;

  ${mq.md} {
    background-color: transparent;
    padding: 0 30px 30px;
  }
`;

export const GuideUpdatesMenuTitle = styled.h3`
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;

  ${mq.md} {
    font-size: 24px;
  }
`;

export const GuideUpdatesListWrapper = styled.div`
  box-sizing: border-box;
  padding: 50px 120px;
  flex: 1;

  ${mq.md} {
    padding: 0 30px;
  }
`;

export const GuideUpdatesList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: 'Inter';
`;

export const GuideItemInfo = styled.div`
  flex: 1;
`;

export const GuideInfoProductName = styled.h4`
  font-size: 18px;
  margin: 0;
`;

export const GuideInfoProductDescription = styled.p`
  font-size: 12px;
  color: #626c76;
`;

export const GuideInfoProductDate = styled.p`
  font-size: 10px;
  color: #626c76;
`;

export const GuideItemIcon = styled.div`
  width: 200px;
  padding: 50px;
  border-radius: 16px;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  ${mq.md} {
    width: auto;
    margin-left: 0;
    border-radius: 8px;
  }
`;

export const GuideUpdateListItem = styled.li`
  border-bottom: solid 1px rgba(48, 152, 166, 0.16);
  margin-bottom: 48px;
  padding-bottom: 48px;

  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    display: flex;
    text-decoration: none;
    color: inherit;
    align-items: flex-start;
  }

  ${mq.md} {
    a {
      flex-direction: row-reverse;

      ${GuideItemIcon} {
        padding: 40px 0;
        margin-right: 20px;
        width: 30%;
      }
    }

    &:first-of-type {
      a {
        flex-direction: column-reverse;
        align-items: stretch;

        ${GuideItemIcon} {
          padding: 80px 0;
          margin-right: 0;
          margin-bottom: 24px;
          width: auto;
        }
      }
    }
  }
`;

export const ProductsOverviewWrapper = styled.div`
  padding: 200px 0 0;
  margin: 0 120px;

  ${mq.md} {
    padding: 0;
    margin: 160px 30px 0;
  }
`;

export const ProductsOverviewTitle = styled.h3`
  line-height: 1.2;
  font-size: 36px;
  font-weight: 600;
  text-align: right;
  margin: 0 0 100px;

  ${mq.md} {
    font-size: 24px;
    text-align: left;
    margin-bottom: 48px;
  }
`;

export const ProductsOverviewProductWrapper = styled.div``;

export const ProductsOverviewProductList = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0;

  &::after {
    display: block;
    content: '';
    width: calc(33.33% - 60px);
  }

  ${mq.md} {
    display: block;
  }
`;

export const ProductsOverviewProductItem = styled.li`
  width: calc(33.33% - 60px);
  border-top: solid 1px rgba(48, 152, 166, 0.16);
  box-sizing: border-box;

  a {
    display: flex;
    padding: 20px 0 64px;
    text-decoration: none;
    color: ${({ theme }) => theme.textColor.primary};
    align-items: center;
    justify-content: space-between;
  }

  p {
    font-size: 16px;
    line-height: 1.4;
    margin: 0;
  }

  .link-icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: rgba(48, 152, 166, 0.08);
    position: relative;

    &::after {
      position: absolute;
      margin: auto;
      width: 12px;
      height: 12px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      content: '';
      background-image: url(/assets/common/index/icon_arrow_right.svg);
      background-size: contain;
    }
  }

  ${mq.md} {
    width: 100%;

    a {
      padding: 20px 0 20px;
    }
  }
`;

export const GuideSection = styled.section`
  padding: 300px 0 236px;
  position: relative;
  overflow: hidden;

  &#developers-guide {
    background-color: #171c21;
    border-radius: 80px;
    margin-top: -80px;

    ${GuideTitle} {
      color: white;
    }

    ${GuideUpdatesMenuWrapper} {
      background-color: rgba(255, 255, 255, 0.02);
      color: white;

      ${mq.md} {
        background-color: transparent;
      }
    }

    ${GuideInfoProductName} {
      color: white;
    }

    ${GuideInfoProductDescription} {
      color: rgba(255, 255, 255, 0.6);
    }

    ${ProductsOverviewTitle} {
      color: white;
    }

    ${ProductsOverviewProductItem} {
      p {
        color: white;
      }
    }
  }

  ${mq.md} {
    padding: 160px 0;

    &#developers-guide {
      border-radius: 40px;
    }
  }
`;
