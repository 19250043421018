import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { mq } from '@/breakpoints';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useLangPath } from '@/i18n';
import { css } from '@emotion/react';
import { isPilotAuto } from '@/util/appConfig';

const Footer: React.FC = () => {
  const { i18n } = useTranslation();
  const langPath = useLangPath();
  const location = useLocation();

  const isTop = useMemo(() => {
    return matchPath(location.pathname, `${langPath}`) ? 1 : 0;
  }, [langPath, location.pathname]);

  return (
    <Wrapper isTop={isTop}>
      <LeftList>
        <li>
          <a
            href="https://twitter.com/tier_iv_japan"
            target="_blank"
            rel="noreferrer"
          >
            TWITTER
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/tier_iv/"
            target="_blank"
            rel="noreferrer"
          >
            INSTAGRAM
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/TIERIV/"
            target="_blank"
            rel="noreferrer"
          >
            FACEBOOK
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/tier-iv-inc/"
            target="_blank"
            rel="noreferrer"
          >
            LINKEDIN
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UC_rY-0gjUuzeqSWakSOUblg/featured"
            target="_blank"
            rel="noreferrer"
          >
            YOUTUBE
          </a>
        </li>
      </LeftList>
      <RightList>
        <li>
          <BaseList>
            <li>
              <a
                href={`https://account.tier4.jp/terms${
                  i18n.language === 'en' ? '' : '?lang=ja'
                }`}
                target="_blank"
                rel="noreferrer"
              >
                TERM
              </a>
            </li>
            <li>
              <a
                href={`https://tier4.jp/${
                  i18n.language === 'en' ? 'en/' : ''
                }privacy/`}
                target="_blank"
                rel="noreferrer"
              >
                PRIVACY
              </a>
            </li>
            {isPilotAuto ? (
              <>
                <li>
                  <a
                    href={`https://web.auto/${
                      i18n.language === 'en' ? '' : 'ja/'
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Web.Auto
                  </a>
                </li>
                <li>
                  <a
                    href={`https://docs.web.auto/${
                      i18n.language === 'en' ? 'en/' : ''
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Web.Auto DOCS
                  </a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <a
                    href={`https://pilot.auto/${
                      i18n.language === 'en' ? '' : 'ja/'
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pilot.Auto
                  </a>
                </li>
                <li>
                  <a
                    href={`https://docs.pilot.auto/${
                      i18n.language === 'en' ? 'en/' : ''
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pilot.Auto DOCS
                  </a>
                </li>
              </>
            )}
          </BaseList>
        </li>
        <li className="corporate">
          <a
            href={`https://tier4.jp/${i18n.language === 'en' ? 'en/' : ''}`}
            target="_blank"
            rel="noreferrer"
          >
            &copy; TIER IV, INC.
          </a>
        </li>
      </RightList>
    </Wrapper>
  );
};

export default Footer;

type WrapperProps = {
  isTop: 0 | 1;
};

const Wrapper = styled.footer<WrapperProps>`
  border-top: solid 1px rgba(48, 152, 166, 0.16);
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  padding: 50px 40px;
  position: relative;

  body[data-type='webauto'] & {
    background-color: #fff;
  }

  body[data-type='pilotauto'] & {
    ${({ isTop }) => {
      if (!isTop) {
        return css`
          background-color: #fff;
        `;
      }
    }}
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.textColor.secondary};
    transition: color 400ms;

    &:hover {
      color: #1f2933;
    }
  }

  ${mq.md} {
    margin: 0;
    padding: 48px 20px;
    justify-content: flex-start;
    align-items: stretch;
  }
`;

const BaseList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  ${mq.md} {
    display: block;
    width: 50%;

    li {
      margin-bottom: 1em;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

const LeftList = styled(BaseList)`
  li {
    margin-right: 24px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  ${mq.md} {
    li {
      margin-right: 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

const RightList = styled(BaseList)`
  justify-content: flex-end;

  li {
    margin-left: 24px;

    &:first-of-type {
      margin-left: 0;
    }

    &.corporate {
      a {
        color: #bdc1c6;
      }
    }
  }

  ${mq.md} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    li {
      margin-left: 0;

      &:first-of-type {
        margin-left: 0;
      }

      &.corporate {
        a {
          color: #bdc1c6;
        }
      }

      ${BaseList} {
        width: auto;
      }
    }
  }
`;
