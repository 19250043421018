import { addEndSlash } from '@/util/endSlash';
import { useCallback, useState } from 'react';
import { documentAPI } from '..';
import { ReleaseNote, ReleaseNotesData } from './types';
export * from './types';

export const useReleaseNotes = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getReleaseNotes = useCallback(
    async (path: string): Promise<ReleaseNotesData> => {
      setIsLoading(true);

      let data: ReleaseNotesData = {
        ja: null,
        en: null,
      };

      const splitRequest = async (
        language: 'ja' | 'en',
        path: string,
      ): Promise<'error' | 'success'> => {
        try {
          const res = await documentAPI.get(path);
          const langData = data[language];
          const mergeList: ReleaseNote[] = langData
            ? [...langData.release_notes, ...res.data.release_notes]
            : res.data.release_notes;
          data = {
            ...data,
            [language]: {
              release_notes: mergeList,
              next_page: res.data.next_page,
            },
          };
          if (res.data.next_page && res.data.next_page.length > 0) {
            await splitRequest(language, res.data.next_page);
          }
          return 'success';
        } catch (err) {
          console.log(err);
          return 'error';
        }
      };

      await splitRequest('ja', `/ja/${addEndSlash(path)}page-1.json`);
      await splitRequest('en', `/en/${addEndSlash(path)}page-1.json`);

      setIsLoading(false);

      return data;
    },
    [],
  );

  return {
    isLoading,
    getReleaseNotes,
  };
};
