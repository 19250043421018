import styled from '@emotion/styled';
import { mq } from '@/breakpoints';
import Button from '@/components/common/Button';
import { useLangPath } from '@/i18n';

const Bottom = () => {
  const langPath = useLangPath();
  return (
    <Section>
      <Circle>
        <CircleContents>
          <Title>
            HOW TO <span>START</span>
          </Title>
          <Description>
            You can see how you can start applications in Web.Auto. At first,
            you need to make TIER IV account. You can get more details in a
            follwing link.
          </Description>
          <Button
            isLink
            href={`${langPath}user-manuals/tier-iv-account/quick-start/`}
          >
            Get Started
          </Button>
        </CircleContents>
      </Circle>
    </Section>
  );
};

export default Bottom;

const Section = styled.section`
  padding: 200px 0;
  overflow: hidden;

  ${mq.md} {
    padding: 210px 0 95px;
  }
`;

const Circle = styled.div`
  width: 960px;
  height: 960px;
  background-color: #f8f9fc;
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: calc((100% - 960px) * 0.5);

  ${mq.md} {
    width: 475px;
    height: 475px;
    margin-left: calc((100% - 475px) * 0.5);
  }
`;

const CircleContents = styled.div`
  width: 400px;
  text-align: center;

  button {
    margin: 0 auto;
  }
`;

const Title = styled.h3`
  line-height: 1.2;
  font-size: 36px;
  font-weight: 600;

  span {
    background: linear-gradient(270deg, #214772 0%, #3098a6 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  ${mq.md} {
    font-size: 20px;
  }
`;

const Description = styled.p`
  font-family: 'Inter';
  font-size: 14px;
  margin-bottom: 3.5em;

  ${mq.md} {
    font-size: 12px;
    width: 80%;
    margin: 2em auto 3em;
  }
`;
