import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from '@/breakpoints';

const whiteSpace = (key: string, property: string | string[]) => {
  let style = `.${key} {`;
  let n = 1;
  while (n <= 100) {
    const num = n * 4;
    style += `&-${num} {`;
    if (typeof property === 'string') {
      style += `${property}: ${num}px;`;
    }
    if (Array.isArray(property)) {
      property.forEach((prop) => {
        style += `${prop}: ${num}px;`;
      });
    }
    style += '}';
    n += 1;
  }
  style += '}';
  return css`
    ${style}
  `;
};

const DocumentWrapper = styled.div`
  box-sizing: border-box;
  padding: 120px 180px;
  background-color: white;
  width: calc(100% - 360px);
  flex: 1;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  position: relative;
  margin: 0 auto;

  ${mq.md} {
    padding: 40px 20px;
    border-radius: 16px 16px 0 0;
    width: 100%;
    max-width: 100%;
  }

  article.markdown-body {
    font-size: 14px;
    max-width: 1200px;

    ${mq.md} {
      max-width: 100%;
    }

    &::before,
    &::after {
      display: none;
      content: none;
    }

    h1 {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    pre {
      border-radius: 6px;
      overflow-x: auto;
    }

    ol,
    ul {
      padding-left: 0.6em;

      ol,
      ul {
        margin-top: 1em;
      }

      li {
        list-style: none;
        margin-bottom: 12px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    ol {
      counter-reset: item;

      li {
        padding-left: 1.3em;
        position: relative;

        &::before {
          position: absolute;
          counter-increment: item;
          content: counter(item);
          color: #3098a6;
          font-weight: 600;
          left: 0;
        }
      }
    }

    ul {
      counter-reset: item;

      li {
        position: relative;
        padding-left: 1em;

        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          background-color: #3098a6;
          border-radius: 3px;
          position: absolute;
          left: 0;
          top: 0.6em;
        }
      }
    }

    ul.release-notes {
      li {
        border-top: solid 1px rgba(48, 152, 166, 0.16);
        padding: 0;

        &:last-of-type {
          border-bottom: solid 1px rgba(48, 152, 166, 0.16);
        }

        a {
          transition: opacity 300ms;
          text-decoration: none;

          &:hover {
            opacity: 0.6;
          }

          ${mq.md} {
            display: block;
          }
        }

        &::before {
          display: none;
        }

        .note-wrapper {
          display: flex;
          padding: 50px 0;
          font-family: 'Inter';
          align-items: flex-start;

          ${mq.md} {
            flex-direction: row-reverse;
            padding: 32px 0 24px;
          }
        }

        .note-contents {
          display: flex;
          flex: 1;

          ${mq.md} {
            display: block;
          }
        }

        .note-date {
          line-height: 1.4;
          color: #bdc1c6;
          font-weight: 600;
          font-size: 16px;
          width: 210px;

          ${mq.md} {
            font-size: 12px;
            font-weight: 400;
            line-height: 1.75;
          }
        }

        .note-info {
          flex: 1;
          width: 100%;

          p {
            margin: 0;

            &.note-title {
              line-height: 1.4;
              font-weight: 700;
              font-size: 16px;
              color: #1f2933;

              ${mq.md} {
                margin: 8px 0 16px;
              }
            }

            &.note-description {
              margin-top: 16px;
              line-height: 1.75;
              font-weight: 400;
              font-size: 14px;
              color: #626c76;
            }
          }
        }

        .note-categories {
          display: flex;
          flex-wrap: wrap;
          color: #1f2933;
          font-size: 10px;
          font-weight: 400;
          padding: 0;
          margin: 32px 0 0;

          ${mq.md} {
            margin-top: 20px;
            margin-bottom: -20px;
          }

          .note-category {
            background: rgba(92, 180, 192, 0.12);
            border-radius: 10px;
            padding: 4px 12px;
            border: none;
            font-family: 'Inter';
            line-height: 1.5;
            margin: 0 20px 0 0;

            &:last-of-type {
              margin-right: 0;
            }

            ${mq.md} {
              margin-bottom: 20px;
            }
          }
        }

        .note-thumbnail {
          width: 200px;
          padding: 50px 0;
          background-position: center;
          background-size: contain;
          background-color: #fff;

          ${mq.md} {
            width: 30%;
            margin-right: 25px;
            padding: 40px 0;
          }
        }
      }
    }

    code {
      background: #f8f9fc;
      border: 1px solid rgba(48, 152, 166, 0.16);
      border-radius: 4px;
      display: inline;
      margin: 0 4px;
      padding: 0 4px;

      &.code-highlight {
        background: none;
        border: none;
        border-radius: none;
        margin: 0;
        padding: 0;
      }

      span {
        &.line-number::before {
          display: inline-block;
          width: 1rem;
          text-align: right;
          margin-right: 16px;
          margin-left: -8px;
          color: rgb(156, 163, 175); /* Line number color */
          content: attr(line);
        }
      }
    }

    table {
      width: auto;
      border-spacing: 0;
      border-collapse: collapse;
      display: block;
      max-width: 100%;
      overflow: auto;
      margin: 1em 0;

      th,
      td {
        padding: 6px 20px;
        border-bottom: 1px solid rgba(48, 152, 166, 0.16);
      }

      td {
        &:first-of-type {
          font-weight: 600;
        }
      }

      th {
        color: #3098a6;
        font-weight: normal;
        border-bottom-width: 2px;
      }

      ${mq.md} {
        width: 100%;
      }
    }

    blockquote {
      border-left: solid 8px #f1f4fa;
      padding: 0 1.5em;
      padding-right: 0;
      margin: 1em 0;

      p {
        margin: 0;
      }
    }

    hr {
      border: none;
      border-top: solid 1px rgba(48, 152, 166, 0.16);
    }

    a {
      color: #3098a6;

      &.data-footnote-backref {
        text-decoration: none;
      }
    }

    sup,
    sub {
      a {
        text-decoration: none;

        &::before {
          content: '[';
        }

        &::after {
          content: ']';
        }
      }
    }

    img,
    svg {
      max-width: 100%;
    }

    details {
      position: relative;
      border: solid 1px rgba(48, 152, 166, 0.16);
      border-left: none;
      border-right: none;
      box-sizing: border-box;
      padding: 0 120px;

      > :nth-child(2) {
        margin-top: 0;
      }

      &::before {
        position: absolute;
        background: #f8f9fc;
        display: block;
        width: 56px;
        height: 56px;
        content: '';
        pointer-events: none;
        z-index: 0;
        border-radius: 50%;
        top: 32px;
        left: 32px;
      }

      summary {
        cursor: pointer;
        list-style: none;
        position: relative;
        font-weight: 600;
        line-height: 56px;
        padding-left: 70px;
        padding: 32px 32px 32px 120px;
        margin-left: -120px;
        font-size: 16px;
        width: calc(100% + 240px);
        transition: background-color 400ms ease-out;

        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 10px;
          height: 2px;
          background-color: #3098a6;
          top: 0;
        }

        &::before {
          left: ${24 + 32}px;
          top: ${27 + 32}px;
          transition: all 300ms;
        }

        &::after {
          left: ${24 + 32}px;
          top: ${27 + 32}px;
          transform: rotate(90deg);
          transition: opacity 300ms;
        }

        &:hover {
          background-color: #fbfbfb;

          &::before {
            width: 6px;
            height: 6px;
            border-radius: 3px;
            top: ${25 + 32}px;
            left: ${26 + 32}px;
          }

          &::after {
            opacity: 0;
          }
        }
      }

      &[open] {
        padding-bottom: 32px;

        summary {
          background-color: transparent;

          &::after {
            opacity: 0;
          }
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      a {
        text-decoration: none;

        &[aria-hidden='true'] {
          margin-left: 0.4em;
          opacity: 0;
          transition: opacity 300ms;

          &:nth-of-type(2) {
            display: none;
          }
        }
      }

      &:hover {
        a {
          opacity: 1;
        }
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.4;
    }

    h2 {
      padding-top: 2em;
      position: relative;

      &::before {
        display: block;
        top: 0;
        position: absolute;
        width: 36px;
        height: 1px;
        content: '';
        background-color: #3098a6;
      }
    }

    // card
    .cards {
      display: flex;
      flex-wrap: wrap;

      a.card-link {
        background-color: #f8f9fc;
        border-radius: 16px;
        width: 404px;
        box-sizing: border-box;
        padding: 40px;
        text-decoration: none;
        color: inherit;
        margin-bottom: 32px;

        &:nth-of-type(odd) {
          margin-right: 32px;
        }

        p.card-title {
          margin-top: 0;
          font-size: 16px;
          font-weight: bold;
          color: #3098a6;
        }

        p.card-description {
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &::after {
          content: '';
          display: block;
          width: 12px;
          height: 12px;
          margin-top: 2em;
          background: url(/assets/common/index/icon_arrow_right.svg);
          transition: transform 200ms ease-out;
        }

        &:hover {
          p.card-title {
            text-decoration: underline;
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }

      ${mq.md} {
        display: block;

        a.card-link {
          width: 100%;
          display: block;
        }
      }
    }

    // link list
    ul.links {
      display: flex;
      list-style: none;
      padding: 0;
      flex-wrap: wrap;
      margin: 1em 0;

      li.link {
        width: calc(50% - 16px);
        min-width: 300px;
        max-width: 414px;
        padding: 0;
        margin-bottom: 0;

        &:nth-of-type(odd) {
          margin-right: 32px;
        }

        &::before {
          display: none;
        }

        a {
          display: block;
          height: 130px;
          border-top: solid 1px rgba(48, 152, 166, 0.16);
          text-decoration: none;
          line-height: 1.4;
          color: #1f2933;
          font-size: 14px;
          padding-top: 34px;
          position: relative;

          &::after {
            content: url(/assets/webauto/common/icon_arrow_right_green.svg);
            background-color: rgba(48, 152, 166, 0.08);
            border-radius: 24px;
            text-align: center;
            box-sizing: border-box;
            padding-top: 14px;
            display: block;
            width: 48px;
            height: 48px;
            position: absolute;
            right: 0;
            top: 20px;
            transition: background-color 300ms ease-out;
          }

          &:hover {
            &::after {
              background-color: rgba(48, 152, 166, 0.16);
            }
          }
        }
      }

      ${mq.md} {
        display: block;

        li.link {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          margin-right: 0;

          a {
            height: auto;
            padding-bottom: 34px;
          }
        }
      }
    }

    // pagination
    .pagination {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 40px;

      .pagination-prev-wrapper,
      .pagination-next-wrapper {
        width: 50%;
        display: flex;
      }

      .pagination-next-wrapper {
        justify-content: flex-end;
      }
    }

    // margin
    ${whiteSpace('m', 'margin')}
    ${whiteSpace('mt', 'margin-top')}
    ${whiteSpace('mb', 'margin-bottom')}
    ${whiteSpace('ml', 'margin-left')}
    ${whiteSpace('mr', 'margin-right')}
    ${whiteSpace('my', ['margin-top', 'margin-bottom'])}
    ${whiteSpace('mx', ['margin-left', 'margin-right'])}

    // padding
    ${whiteSpace('p', 'padding')}
    ${whiteSpace('pt', 'padding-top')}
    ${whiteSpace('pb', 'padding-bottom')}
    ${whiteSpace('pl', 'padding-left')}
    ${whiteSpace('pr', 'padding-right')}
    ${whiteSpace('py', ['padding-top', 'padding-bottom'])}
    ${whiteSpace('px', ['padding-left', 'padding-right'])}

    body[data-lang='en'] & {
      h1 {
        font-size: 48px;
        line-height: 1.2;
      }

      h2 {
        font-size: 36px;
      }

      h3 {
        font-size: 24px;
      }

      h4 {
        font-size: 18px;
      }

      h5 {
        font-size: 14px;
      }

      h6 {
        font-size: 10px;
      }

      ${mq.md} {
        h1 {
          font-size: 32px;
          line-height: 1.2;
        }

        h2 {
          font-size: 24px;
        }

        h3 {
          font-size: 18px;
        }

        h4 {
          font-size: 14px;
        }

        h5 {
          font-size: 12px;
        }

        h6 {
          font-size: 10px;
        }
      }
    }

    body[data-lang='ja'] & {
      h1 {
        font-size: 44px;
        line-height: 1.4;
      }

      h2 {
        font-size: 32px;
      }

      h3 {
        font-size: 22px;
      }

      h4 {
        font-size: 16px;
      }

      h5 {
        font-size: 14px;
      }

      h6 {
        font-size: 10px;
      }

      ${mq.md} {
        h1 {
          font-size: 28px;
        }

        h2 {
          font-size: 22px;
        }

        h3 {
          font-size: 16px;
        }

        h4 {
          font-size: 14px;
        }

        h5 {
          font-size: 12px;
        }

        h6 {
          font-size: 10px;
        }
      }
    }
  }
`;

export default DocumentWrapper;
