import { mq } from '@/breakpoints';
import {
  AuthorityTable as AuthorityTableData,
  useAuthorityTable,
} from '@/services/config/authorityTable';
import { useLink } from '@/util/markdown';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

type Props = {
  serviceName: string;
};

type VisibleType = 'summary' | 'detail';

const AuthorityTable: React.FC<Props> = ({ serviceName }) => {
  const [visibleType, setVisibleType] = useState<VisibleType>('summary');
  const [authorityTable, setAuthorityTable] =
    useState<AuthorityTableData | null>(null);
  const { getAuthorityTable } = useAuthorityTable();
  const { t } = useTranslation();
  const { getLink } = useLink();

  const handleClickSummary = useCallback(() => {
    setVisibleType('summary');
  }, []);

  const handleClickDetail = useCallback(() => {
    setVisibleType('detail');
  }, []);

  const tableBody = useMemo(() => {
    if (!authorityTable) return <></>;
    return (
      <>
        {authorityTable.groups.map((group, i) => {
          const groupName = (
            <tr>
              <td className="action-name">{group.name}</td>
              {[...Array(authorityTable.roles.length + 1)].map((_, j) => (
                <td key={`action-td${j}`}>&nbsp;</td>
              ))}
            </tr>
          );

          const parameterName = group.parameters.map((parameter, j) => (
            <tr key={`parameter-tr${j}`}>
              <td className="parameter-name">
                {t('authority_table.parameter.apply_to')}
              </td>
              <td>&nbsp;</td>
              {parameter.result.map((result, k) => {
                if (result === 'None') {
                  return (
                    <td className="parameter-none" key={`parameter-td${k}`} />
                  );
                }
                const value = `${t(
                  `authority_table.parameter.result.${result.toLowerCase()}`,
                )}${parameter.name}`;
                return (
                  <td key={`parameter-td${k}`} className="parameter-value">
                    {value}
                  </td>
                );
              })}
            </tr>
          ));

          const actions = group.actions.map((action, j) => {
            if (visibleType === 'summary') {
              return (
                <tr key={`action-tr${j}`}>
                  <td>
                    {action.name}
                    {!!action.docs_path &&
                      action.docs_path.length > 0 &&
                      getLink({
                        href: action.docs_path,
                        children: <>?</>,
                      })}
                  </td>
                  <td>nbsp;</td>
                  {authorityTable.roles.map((_, k) => (
                    <td key={`result${k}`}>
                      {action.result[k] ? <span /> : <>&nbsp;</>}
                    </td>
                  ))}
                </tr>
              );
            }
            return (
              <>
                {action.scopes.map((scope, k) => (
                  <tr key={`${scope.name}${k}`}>
                    <td>
                      {k === 0 ? (
                        <>
                          {action.name}
                          <a href={action.docs_path} rel="noreferrer noopener">
                            ?
                          </a>
                        </>
                      ) : (
                        <>&nbsp;</>
                      )}
                    </td>
                    <td className="scope-list">{scope.name}</td>
                    {authorityTable.roles.map((_, l) => (
                      <td key={`${scope.name}${k}${l}`}>
                        {scope.result[l] ? <span /> : <>&nbsp;</>}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            );
          });

          return (
            <React.Fragment key={`group${i}`}>
              {groupName}
              {parameterName}
              {actions}
            </React.Fragment>
          );
        })}
      </>
    );
  }, [authorityTable, visibleType, t, getLink]);

  const table = useMemo(() => {
    if (!authorityTable) return <></>;
    return (
      <Table
        data-column-count={authorityTable.roles.length + 2}
        data-visible={visibleType}
      >
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>
              <span>SCOPE</span>
            </th>
            {authorityTable.roles.map((role) => (
              <th key={role}>
                <span>{role}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  }, [authorityTable, tableBody, visibleType]);

  useMount(async () => {
    const res = await getAuthorityTable(serviceName);
    setAuthorityTable(res);
  });

  if (!serviceName || !authorityTable) return null;

  return (
    <Wrapper>
      <NavigationWrapper>
        <NavigationButton
          selected={visibleType === 'summary'}
          onClick={handleClickSummary}
        >
          SUMMARY
        </NavigationButton>
        <NavigationButton
          selected={visibleType === 'detail'}
          onClick={handleClickDetail}
        >
          DETAIL
        </NavigationButton>
      </NavigationWrapper>
      <TableWrapper>{table}</TableWrapper>
    </Wrapper>
  );
};

export default React.memo(AuthorityTable);

const tdWidth = (roleCount: number) => {
  const columnCount = roleCount + 2;
  const style = `
    &[data-column-count='${columnCount}'] {
      &[data-visible='summary'] {
        th,
        td {
          width: ${100 / (columnCount - 1)}%;
        }
      }
      &[data-visible='detail'] {
        th,
        td {
          width: ${100 / columnCount}%;
        }
      }
    }
  `;
  return css`
    ${style}
  `;
};

const Wrapper = styled.div`
  position: relative;
`;

const NavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 28px 0;
  position: sticky;
  top: 68px;
  background-color: white;
  z-index: 1;

  ${mq.md} {
    top: 0;
    position: relative;
    padding: 14px 0;
  }
`;

type NavigationButtonProps = {
  selected: boolean;
};

const NavigationButton = styled.button<NavigationButtonProps>`
  appearance: none;
  border: none;
  background: none;
  font-family: futura-pt;
  padding: 0;
  line-height: 1;
  font-weight: 450;
  font-size: 20px;
  margin: 0 20px;
  letter-spacing: 0.2em;
  cursor: pointer;

  ${({ selected }) => {
    if (selected) {
      return css`
        color: #6eb6cc;
        font-weight: 550;

        &::after {
          display: block;
          width: 100%;
          height: 1px;
          margin-top: 2px;
          background-color: #6eb6cc;
          content: '';
        }
      `;
    }
  }}
`;

const TableWrapper = styled.div`
  ${mq.md} {
    overflow: auto;
  }
`;

const Table = styled.table`
  width: 100% !important;
  display: table !important;
  font-family: futura-pt;
  font-weight: 450;
  border-collapse: collapse;

  thead,
  tbody {
    width: 100%;
  }

  tbody {
    tr {
      &:hover {
        background-color: #c9d4f5;
      }
    }
  }

  ${mq.md} {
    width: 900px !important;
    max-width: unset !important;
  }

  th,
  td {
    padding: 10px 20px;
    text-align: center;
    box-sizing: border-box;
    border-bottom: none !important;
    word-break: break-word;

    &:first-of-type {
      text-align: right;
    }
  }

  &[data-visible='summary'] {
    th,
    td {
      &:nth-of-type(2) {
        display: none;
      }
    }
  }

  ${tdWidth(1)};
  ${tdWidth(2)};
  ${tdWidth(3)};
  ${tdWidth(4)};
  ${tdWidth(5)};
  ${tdWidth(6)};
  ${tdWidth(7)};
  ${tdWidth(8)};

  thead {
    margin-bottom: 4px;
  }

  th {
    position: relative;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.2em;
    position: sticky;
    z-index: 1;
    top: ${68 + 79}px;
    background-color: white;

    ${mq.md} {
      top: 0;
    }

    span {
      position: relative;
    }

    &::before {
      position: absolute;
      bottom: 0;
      left: 2px;
      width: calc(100% - 4px);
      height: 100%;
      content: '';
      display: block;
      z-index: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:nth-of-type(2) {
      color: black !important;

      &::before {
        background-color: #ececec;
      }
    }
    &:nth-of-type(3n + 3) {
      color: white !important;

      &::before {
        background-color: #101224;
      }
    }
    &:nth-of-type(3n + 4) {
      color: black !important;

      &::before {
        background-color: #6eb6cc;
      }
    }
    &:nth-of-type(3n + 5) {
      color: black !important;

      &::before {
        background-color: #d9d9d9;
      }
    }
  }

  td {
    border-left: 1px solid black;
    font-size: 12px;

    &:first-of-type {
      border-left: none;

      a {
        display: inline-block;
        text-decoration: none;
        border: solid 1px #3098a6;
        border-radius: 50%;
        line-height: 14px;
        width: 16px;
        height: 16px;
        text-align: center;
        margin-left: 4px;
      }
    }

    &:nth-of-type(n + 2) {
      span {
        visibility: hidden;
        display: block;
        line-height: 0;

        &::after {
          content: '';
          margin: 0 auto;
          visibility: visible;
          display: block;
          box-sizing: border-box;
          width: 12px;
          height: 8px;
          border-left: solid 2px black;
          border-bottom: solid 2px black;
          transform: rotate(-45deg);
        }
      }
    }

    &:last-of-type {
      position: relative;
      &::after {
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: black;
        content: '';
        display: block;
      }
    }

    &.action-name {
      font-size: 16px;
    }

    &.scope-list {
      text-align: left;
      word-break: break-all;
    }

    &.parameter-name {
      font-size: 14px;
      text-transform: uppercase;
    }

    &.parameter-none {
      position: relative;

      &::before {
        display: block;
        content: '';
        width: 10px;
        height: 1px;
        background-color: black;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }

    &.parameter-value {
      text-transform: uppercase;
    }
  }
`;
