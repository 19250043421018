import { authenticationManager as manager } from '@tier4/webauto-auth-frontend-client';

export const authenticationManager = manager({
  authority: import.meta.env.VITE_OIDC_PROVIDER_ENDPOINT ?? '',
  client_id: import.meta.env.VITE_OIDC_CLIENT_ID ?? '',
  redirect_uri: import.meta.env.VITE_OIDC_LOGIN_CALLBACK_URI ?? '',
  silent_redirect_uri: import.meta.env.VITE_OIDC_LOGIN_CALLBACK_URI ?? '',
  tokenServiceAPIUrl: `https://${import.meta.env.VITE_AUTH_API_DOMAIN}`,
  post_logout_redirect_uri: import.meta.env.VITE_OIDC_LOGOUT_CALLBACK_URI ?? '',
  response_type: 'code',
  automaticSilentRenew: true,
  revokeTokensOnSignout: true,
  scope: 'openid',
  logLevel: import.meta.env.VITE_OIDC_LOG_LEVEL,
  logMethod: {
    ...console,
    error(errorMessage: string) {
      console.log(errorMessage);
    },
  },
  afterSignOutPropagation: () => {
    window.location.href = '/';
  },
});

export const signInRedirect = async () =>
  authenticationManager.userManager.signInRedirect();
