import { mq } from '@/breakpoints';
import { useLangPath } from '@/i18n';
import { getBranchLink } from '@/util/branch';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IndexContentsWrapper } from '../styled';

const Application: React.FC = () => {
  const { t } = useTranslation();
  const langPath = useLangPath();

  return (
    <Wrapper>
      <IndexContentsWrapper>
        <Title>CLASSIFICATION</Title>
        <ContentsWrapper>
          <Lead>{t('pilotauto.top.odd.classification.description')}</Lead>
          <ODDList>
            <ODDItem>
              <ItemInfo>
                <ItemDescriptionWrapper>
                  <ItemTitle>ODD 1</ItemTitle>
                  <ItemDescription>
                    {t('pilotauto.top.odd.classification.odd1.description')}
                  </ItemDescription>
                </ItemDescriptionWrapper>
                <ItemCaptionWrapper>
                  <p>
                    {t('pilotauto.top.odd.classification.odd1.point1.title')}
                  </p>
                  <ul>
                    <li>
                      {t('pilotauto.top.odd.classification.odd1.point1.item1')}
                    </li>
                    <li>
                      {t('pilotauto.top.odd.classification.odd1.point1.item2')}
                    </li>
                  </ul>
                  <p>
                    {t('pilotauto.top.odd.classification.odd1.point2.title')}
                  </p>
                  <ul>
                    <li>
                      {t(`pilotauto.top.odd.classification.odd1.point2.item1`)}
                    </li>
                  </ul>
                  <p>
                    {t('pilotauto.top.odd.classification.odd1.point3.title')}
                  </p>
                  <ul>
                    <li>
                      {t(`pilotauto.top.odd.classification.odd1.point3.item1`)}
                    </li>
                    <li>
                      {t(`pilotauto.top.odd.classification.odd1.point3.item2`)}
                    </li>
                    <li>
                      {t(`pilotauto.top.odd.classification.odd1.point3.item3`)}
                    </li>
                  </ul>
                </ItemCaptionWrapper>
              </ItemInfo>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/odd_list_1_image_1.jpg" />
              </ItemImageWrapper>
            </ODDItem>
            <ODDItem>
              <ItemInfo>
                <ItemDescriptionWrapper>
                  <ItemTitle>ODD 2</ItemTitle>
                  <ItemDescription>
                    {t('pilotauto.top.odd.classification.odd2.description')}
                  </ItemDescription>
                </ItemDescriptionWrapper>
                <ItemCaptionWrapper>
                  <p>
                    {t('pilotauto.top.odd.classification.odd2.point1.title')}
                  </p>
                  <ul>
                    <li>
                      {t(`pilotauto.top.odd.classification.odd2.point1.item1`)}
                    </li>
                    <li>
                      {t(`pilotauto.top.odd.classification.odd2.point1.item2`)}
                    </li>
                    <li>
                      {t(`pilotauto.top.odd.classification.odd2.point1.item3`)}
                    </li>
                  </ul>
                </ItemCaptionWrapper>
              </ItemInfo>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/odd_list_1_image_2.jpg" />
              </ItemImageWrapper>
            </ODDItem>
            <ODDItem>
              <ItemInfo>
                <ItemDescriptionWrapper>
                  <ItemTitle>ODD 3</ItemTitle>
                  <ItemDescription>
                    {t('pilotauto.top.odd.classification.odd3.description')}
                  </ItemDescription>
                </ItemDescriptionWrapper>
                <ItemCaptionWrapper>
                  <p>
                    {t('pilotauto.top.odd.classification.odd3.point1.title')}
                  </p>
                  <ul>
                    <li>
                      {t('pilotauto.top.odd.classification.odd3.point1.item1')}
                    </li>
                    <li>
                      {t('pilotauto.top.odd.classification.odd3.point1.item2')}
                    </li>
                  </ul>
                  <p>
                    {t('pilotauto.top.odd.classification.odd3.point2.title')}
                  </p>
                  <ul>
                    <li>
                      {t('pilotauto.top.odd.classification.odd3.point2.item1')}
                    </li>
                  </ul>
                </ItemCaptionWrapper>
              </ItemInfo>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/odd_list_1_image_3.jpg" />
              </ItemImageWrapper>
            </ODDItem>
          </ODDList>
        </ContentsWrapper>
      </IndexContentsWrapper>
      <IndexContentsWrapper>
        <Title>APPLICATION</Title>
        <ContentsWrapper>
          <Lead>{t('pilotauto.top.odd.reference_design.description')}</Lead>
          <ODDList>
            <ODDItem>
              <ItemInfo>
                <ItemDescriptionWrapper>
                  <ItemTitle>Delivery Robot</ItemTitle>
                  <ItemDescription>
                    {t(
                      'pilotauto.top.odd.reference_design.delivery_robot.description',
                    )}
                  </ItemDescription>
                </ItemDescriptionWrapper>
                <ItemLinks>
                  <Link
                    to={getBranchLink(
                      `${langPath}reference-design/delivery-robot/overview/`,
                    )}
                  >
                    Delivery Robot Reference Design
                  </Link>
                </ItemLinks>
              </ItemInfo>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/odd_list_2_image_1.jpg" />
              </ItemImageWrapper>
            </ODDItem>
            <ODDItem>
              <ItemInfo>
                <ItemDescriptionWrapper>
                  <ItemTitle>Cargo Transport</ItemTitle>
                  <ItemDescription>
                    {t(
                      'pilotauto.top.odd.reference_design.cargo_transport.description',
                    )}
                  </ItemDescription>
                </ItemDescriptionWrapper>
                <ItemLinks>
                  <Link
                    to={getBranchLink(
                      `${langPath}reference-design/cargo-transport/overview/`,
                    )}
                  >
                    Cargo Transport Reference Design
                  </Link>
                </ItemLinks>
              </ItemInfo>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/odd_list_2_image_2.jpg" />
              </ItemImageWrapper>
            </ODDItem>
            <ODDItem>
              <ItemInfo>
                <ItemDescriptionWrapper>
                  <ItemTitle>Shuttle Bus</ItemTitle>
                  <ItemDescription>
                    {t(
                      'pilotauto.top.odd.reference_design.shuttle_bus.description',
                    )}
                  </ItemDescription>
                </ItemDescriptionWrapper>
                <ItemLinks>
                  <Link
                    to={getBranchLink(
                      `${langPath}reference-design/shuttle-bus/overview/`,
                    )}
                  >
                    Shuttle Bus Reference Design
                  </Link>
                </ItemLinks>
              </ItemInfo>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/odd_list_2_image_3.jpg" />
              </ItemImageWrapper>
            </ODDItem>
            <ODDItem>
              <ItemInfo>
                <ItemDescriptionWrapper>
                  <ItemTitle>Robo-Taxi</ItemTitle>
                  <ItemDescription>
                    {t(
                      'pilotauto.top.odd.reference_design.robo_taxi.description',
                    )}
                  </ItemDescription>
                </ItemDescriptionWrapper>
                <ItemLinks>
                  <Link
                    to={getBranchLink(
                      `${langPath}reference-design/robo-taxi/overview/`,
                    )}
                  >
                    Robo-Taxi Reference Design
                  </Link>
                </ItemLinks>
              </ItemInfo>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/odd_list_2_image_4.jpg" />
              </ItemImageWrapper>
            </ODDItem>
            <ODDItem>
              <ItemInfo>
                <ItemDescriptionWrapper>
                  <ItemTitle>Personal Car</ItemTitle>
                  <ItemDescription>
                    {t(
                      'pilotauto.top.odd.reference_design.personal_car.description',
                    )}
                  </ItemDescription>
                </ItemDescriptionWrapper>
                <ItemLinks>
                  <Link
                    to={getBranchLink(
                      `${langPath}reference-design/personal-car/overview/`,
                    )}
                  >
                    Personal Car Reference Design
                  </Link>
                </ItemLinks>
              </ItemInfo>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/odd_list_2_image_5.jpg" />
              </ItemImageWrapper>
            </ODDItem>
          </ODDList>
        </ContentsWrapper>
      </IndexContentsWrapper>
    </Wrapper>
  );
};

export default Application;

const Wrapper = styled.div`
  font-family: 'Inter';
  padding: 200px 0;

  ${mq.md} {
    padding: 160px 0 120px;
  }

  ${IndexContentsWrapper} {
    margin-bottom: 200px;

    &:last-of-type {
      margin-bottom: 0;
    }

    ${mq.md} {
      margin: 0 30px 160px;
    }
  }
`;

const Title = styled.h2`
  color: #1f2933;
  font-size: 48px;
  margin: 0;
  line-height: 1.2;

  ${mq.md} {
    font-size: 40px;
    font-family: futura-pt;
    font-weight: 600;
    line-height: 1;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  ${mq.md} {
    display: block;
    margin-top: 48px;
  }
`;

const Lead = styled.p`
  margin: 0 40px 0 0;
  width: 440px;
  font-size: 14px;
  line-height: 1.75;

  ${mq.md} {
    font-size: 12px;
    margin: 0;
    width: auto;
  }
`;

const ODDList = styled.ul`
  width: 600px;
  padding: 0;
  margin: 0;
  list-style: none;

  ${mq.md} {
    width: 100%;
    margin-top: 48px;
  }
`;

const ODDItem = styled.li`
  display: flex;
  justify-content: space-between;
  border-top: solid 1px rgba(48, 152, 166, 0.16);
  padding: 48px 0;

  &:last-of-type {
    border-bottom: solid 1px rgba(48, 152, 166, 0.16);
  }

  ${mq.md} {
    padding: 40px 0;
    width: 100%;
    flex-direction: row-reverse;
  }
`;

const ItemInfo = styled.div`
  flex: 1;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mq.md} {
    padding: 0 0 0 24px;
  }
`;

const ItemTitle = styled.p`
  margin: 0 0 12px;
  font-size: 18px;
  line-height: 1.4;
  color: #1f2933;
  font-weight: 700;

  ${mq.md} {
    font-size: 14px;
  }
`;

const ItemDescriptionWrapper = styled.div``;

const ItemDescription = styled.p`
  font-size: 12px;
  line-height: 1.5;
  color: #626c76;
  white-space: pre-line;
`;

const ItemCaptionWrapper = styled.div`
  font-size: 10px;
  color: #626c76;
  line-height: 1.5;

  p {
    margin: 1em 0 0;

    &:first-of-type {
      margin: 0;
    }
  }

  ul {
    list-style-position: inside;
    list-style-type: disc;
    padding: 0;
    margin: 0 0 0 6px;

    li {
      text-indent: -1.4em;
      padding-left: 1.4em;
    }

    ul {
      padding: 0;
      margin-left: 0;
    }
  }
`;

const ItemLinks = styled.div`
  display: flex;

  ${mq.md} {
    display: block;
  }

  a {
    color: #1f2933;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.75;
    margin-right: 24px;
    display: block;
    padding-left: 1.6em;
    text-indent: -1.6em;

    &:last-of-type {
      margin-right: 0;
    }

    &::before {
      display: inline-block;
      content: '';
      background-image: url(/assets/common/common/icon_arrow_right_green.svg);
      width: 12px;
      height: 12px;
      margin-right: 6px;
      vertical-align: middle;
      transition: transform 200ms ease-out;
    }

    &:hover {
      &::before {
        transform: rotate(-45deg);
      }
    }

    ${mq.md} {
      margin-right: 0;
      font-size: 12px;
    }
  }
`;

const ItemImageWrapper = styled.div`
  width: 200px;

  img {
    display: block;
    width: 100%;
    border-radius: 16px;
  }

  ${mq.md} {
    width: 30%;
  }
`;
