import { mq } from '@/breakpoints';
import { css, Global, useTheme } from '@emotion/react';

const GlobaleStyles = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

        body {
          color: ${theme.color.primary};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          line-height: 1.75;

          &[data-lang='en'] {
            font-family: 'Inter', sans-serif;
          }

          &[data-lang='ja'] {
            font-family: noto-sans-cjk-jp, sans-serif;
            font-weight: 400;
          }
        }

        input {
          &[type='search'] {
            outline: none;
            border: none;

            &:focus {
              // border: solid 1px #66afe9;
            }
          }
        }

        br {
          &.pc {
            display: block;

            ${mq.md} {
              display: none;
            }
          }

          &.sp {
            display: none;

            ${mq.md} {
              display: block;
            }
          }
        }
      `}
    />
  );
};

export default GlobaleStyles;
