type MediaQuery = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
};

type Breakpoints = {
  [P in keyof MediaQuery]: number;
};

const breakpoints: Breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const mq = Object.fromEntries(
  Object.entries(breakpoints).map(([key, val]) => [
    key,
    `@media (max-width: ${val}px)`,
  ]),
) as MediaQuery;
