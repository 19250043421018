import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { documentAPI } from '..';
import { Product, ProductType } from './types';
export * from './types';

/**
 * プロダクト一覧取得
 * NOTE: トップページで利用
 */
export const useProducts = () => {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const getProducts = useCallback(
    async (type: ProductType): Promise<Product[]> => {
      setIsLoading(true);

      try {
        const res = await documentAPI.get(
          `/${i18n.language}/${type}/products.json`,
        );
        const ignoreLanguage = (res.data as Product[]).map((product) => ({
          ...product,
          link: product.link.replace('/ja/', '').replace('/en/', ''),
        }));
        return ignoreLanguage;
      } catch (err) {
        console.log(err);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [i18n.language],
  );

  return {
    isLoading,
    getProducts,
  };
};
