import { mq } from '@/breakpoints';
import styled from '@emotion/styled';
import { IndexImageWrapper } from '../styled';
import throttle from 'lodash.throttle';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ReferenctDesign: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleScrollWindow = () => {
      let scrollTop = document.body.scrollTop;
      if (document.scrollingElement) {
        scrollTop = document.scrollingElement.scrollTop;
      }
      const modelListElement = document.querySelector('.model-list');
      const flowListElement = document.querySelector('.flow-list');
      if (!modelListElement || !flowListElement) return;
      const { children } = modelListElement;
      if (!children) return;
      Array.prototype.forEach.call(children, (child: HTMLElement, i) => {
        const rect = child.getBoundingClientRect();
        const top = rect.top + window.scrollY - window.innerHeight * 0.5;
        if (scrollTop > top) {
          Array.prototype.forEach.call(
            flowListElement.children,
            (flowChild: HTMLElement, j) => {
              if (i + 1 === j) {
                if (!flowChild.classList.contains('show')) {
                  flowChild.classList.add('show');
                }
              } else {
                if (flowChild.classList.contains('show')) {
                  flowChild.classList.remove('show');
                }
              }
            },
          );
        }
      });
    };

    const throttled = throttle(handleScrollWindow, 100);
    window.addEventListener('scroll', throttled);
    return () => window.removeEventListener('scroll', throttled);
  }, []);

  return (
    <Wrapper>
      <IndexImageWrapper />
      <Section>
        <Title>
          <span className="overview">REFERENCE DESIGN OVERVIEW</span>
          SCALING POWER, PERFORMANCE,
          <br className="pc" /> AND COST FOR THE CUSTOMER
        </Title>
        <ContentsWrapper>
          <FlowImageWrapper className="flow-list">
            <img
              src="/assets/pilotauto/index/reference_model_1.png"
              className="show"
            />
            <img src="/assets/pilotauto/index/reference_model_2.png" />
            <img src="/assets/pilotauto/index/reference_model_4.png" />
            <img src="/assets/pilotauto/index/reference_model_5.png" />
            <img src="/assets/pilotauto/index/reference_model_6.png" />
            <img src="/assets/pilotauto/index/reference_model_7.png" />
            <img src="/assets/pilotauto/index/reference_model_8.png" />
          </FlowImageWrapper>
          <ModelList className="model-list">
            <ModelItem>
              <ItemTitle>
                CUSTOMER&apos;S
                <br className="pc" /> BUSINESS MODEL
              </ItemTitle>
              <ItemPointList>
                {[...Array(6)].map((_, i) => (
                  <PointItem key={`business_model${i}`}>
                    {t(
                      `pilotauto.top.reference_design.list.business_model.list.item${
                        i + 1
                      }`,
                    )}
                  </PointItem>
                ))}
              </ItemPointList>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/reference_list_image_1.jpg" />
              </ItemImageWrapper>
              <CustomersDescription>
                {t('pilotauto.top.reference_design.list.description')}
              </CustomersDescription>
            </ModelItem>
            <ModelItem>
              <ItemTitle>CONCEPT</ItemTitle>
              <ItemPointList>
                {[...Array(2)].map((_, i) => (
                  <PointItem key={`concept${i}`}>
                    {t(
                      `pilotauto.top.reference_design.list.concept.list.item${
                        i + 1
                      }`,
                    )}
                  </PointItem>
                ))}
              </ItemPointList>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/reference_list_image_2.jpg" />
              </ItemImageWrapper>
            </ModelItem>
            <ModelItem>
              <ItemTitle>USE CASES</ItemTitle>
              <ItemPointList>
                <PointItem>
                  {t(
                    `pilotauto.top.reference_design.list.use_cases.list.item1`,
                  )}
                </PointItem>
              </ItemPointList>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/reference_list_image_3.jpg" />
              </ItemImageWrapper>
            </ModelItem>
            <ModelItem>
              <ItemTitle>FEATURE</ItemTitle>
              <ItemPointList>
                {[...Array(2)].map((_, i) => (
                  <PointItem key={`feature${i}`}>
                    {t(
                      `pilotauto.top.reference_design.list.feature.list.item${
                        i + 1
                      }`,
                    )}
                  </PointItem>
                ))}
              </ItemPointList>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/reference_list_image_4.jpg" />
              </ItemImageWrapper>
            </ModelItem>
            <ModelItem>
              <ItemTitle>SYSTEM REQUIREMENTS</ItemTitle>
              <ItemPointList>
                {[...Array(3)].map((_, i) => (
                  <PointItem key={`system${i}`}>
                    {t(
                      `pilotauto.top.reference_design.list.system_requirements.list.item${
                        i + 1
                      }`,
                    )}
                  </PointItem>
                ))}
              </ItemPointList>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/reference_list_image_5.jpg" />
              </ItemImageWrapper>
            </ModelItem>
            <ModelItem>
              <ItemTitle>CUSTOMER’S PRODUCTS</ItemTitle>
              <ItemPointList>
                {[...Array(4)].map((_, i) => (
                  <PointItem key={`software${i}`}>
                    {t(
                      `pilotauto.top.reference_design.list.customers_products.list.item${
                        i + 1
                      }`,
                    )}
                  </PointItem>
                ))}
              </ItemPointList>
              <ItemImageWrapper>
                <img src="/assets/pilotauto/index/reference_list_image_6.jpg" />
              </ItemImageWrapper>
            </ModelItem>
          </ModelList>
        </ContentsWrapper>
      </Section>
    </Wrapper>
  );
};

export default ReferenctDesign;

const Wrapper = styled.div`
  position: relative;

  ${IndexImageWrapper} {
    background-image: url(/assets/pilotauto/index/image_2.jpg);
    padding-top: 55%;

    ${mq.md} {
      background-image: url(/assets/pilotauto/index/image_2_sp.jpg);
    }
  }
`;

const Section = styled.section`
  background-color: #171b21;
  padding: 200px 0;
  position: relative;
  margin-top: -145px;
  border-radius: 80px;
  color: #fff;
  font-family: 'Inter';

  ${mq.md} {
    margin-top: -40px;
    border-radius: 40px;
    padding: 160px 0 50px;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin: 0 auto 120px;
  line-height: 1.2;
  font-size: 48px;
  font-weight: 600;
  max-width: 813px;

  ${mq.md} {
    font-size: 40px;
    text-align: left;
    margin: 0 20px 48px;
    font-family: futura-pt;
    line-height: 1;
  }

  span.overview {
    display: block;
    font-size: 14px;
    text-align: left;
    color: #5cb4c0;
    margin-bottom: 10px;
    font-family: futura-pt;
    line-height: 1;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 120px;

  ${mq.md} {
    margin: 0 20px;
    display: block;
  }
`;

const FlowImageWrapper = styled.div`
  width: 40vw;
  position: sticky;
  height: 100%;
  top: 100px;
  max-width: 500px;

  img {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    opacity: 0;
    transition: opacity 300ms linear;

    &.show {
      position: relative;
      opacity: 1;
    }
  }

  ${mq.md} {
    width: auto;
    position: relative;
    top: 0;
    height: auto;
  }
`;

const ModelList = styled.ul`
  padding: 0;
  margin: 0;
  width: 600px;
  list-style: none;

  ${mq.md} {
    width: auto;
    margin: 46px 0 0;
  }
`;

const ModelItem = styled.li`
  margin-bottom: 160px;
  width: 360px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${mq.md} {
    width: auto;
    margin-bottom: 100px;
  }
`;

const ItemTitle = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  font-family: 'futura-pt';

  ${mq.md} {
    font-size: 18px;
  }
`;

const ItemPointList = styled.ul`
  margin: 30px 0;
  list-style: disc;
  list-style-position: inside;
  padding: 0 0 0 10px;
  color: #bdc1c6;
  font-size: 18px;

  body[data-lang='en'] & {
    font-weight: 500;
  }

  body[data-lang='ja'] & {
    font-weight: 400;
  }

  ${mq.md} {
    margin: 24px 0 36px;
    color: #e1e4e5;
    font-size: 12px;
    font-weight: 400;
  }
`;

const PointItem = styled.li`
  padding-left: 1em;
  text-indent: -1em;
`;

const ItemImageWrapper = styled.div`
  img {
    border-radius: 20px;
    display: block;
    width: 100%;
  }
`;

const CustomersDescription = styled.p`
  margin-top: 160px;
  margin-bottom: 0;
  font-size: 24px;
  letter-spacing: 0.01em;
  line-height: 1.4;

  body[data-lang='en'] & {
    font-weight: 500;
    font-family: futura-pt;
    font-style: normal;
  }

  ${mq.md} {
    margin-top: 2em;
    font-size: 18px;
  }
`;
