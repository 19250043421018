import axios, { AxiosRequestHeaders } from 'axios';
import { getAPIBranchPath } from '@/util/branch';
import { getBearerToken } from '../auth/token/authorization';
import { authenticationManager } from '@/util/authenticationManager';
import { documentAPIOrigin } from '@/util/appConfig';

export const documentAPI = axios.create({
  baseURL: `${documentAPIOrigin}${getAPIBranchPath()}`,
  responseType: 'json',
});

documentAPI.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    } as unknown as AxiosRequestHeaders;
    const user = await authenticationManager.userManager.getUser();
    if (user) {
      const bearer = await getBearerToken();
      if (bearer && bearer.accessToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${bearer.accessToken}`,
        } as unknown as AxiosRequestHeaders;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export * from './entry';
export * from './navigation';
export * from './products';
export * from './release-notes';
