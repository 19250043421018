import GlobaleStyles from '@/styles/GlobalStyles';
import Routes from '@/Routets';
import { useEffect, useRef } from 'react';
import { isEnableAuth } from '@/util/appConfig';

const App = () => {
  const first = useRef(true);

  useEffect(() => {
    if (!isEnableAuth) return;
    if (process.env.NODE_ENV === 'development' && first.current) {
      first.current = false;
      return;
    }
  }, []);

  return (
    <>
      <GlobaleStyles />
      <Routes />
    </>
  );
};

export default App;
