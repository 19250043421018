import styled from '@emotion/styled';
import { mq } from '@/breakpoints';

const LowerWrapper = styled.div`
  display: flex;
  width: 100%;

  ${mq.md} {
    display: block;
  }
`;

export default LowerWrapper;
