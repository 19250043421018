import { useCallback, useState } from 'react';
import { AuthorityTable } from './types';
import axios from 'axios';
import store from 'store2';
export * from './types';

export const useAuthorityTable = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getAuthorityTable = useCallback(
    async (service: string): Promise<AuthorityTable | null> => {
      setIsLoading(true);

      try {
        // const res = await configAPI.get(`/authority_tables/${service}`);
        const res = await axios.get(
          `https://${import.meta.env.VITE_CONFIG_API_DOMAIN}/${
            import.meta.env.VITE_CONFIG_API_VERSION
          }/authority_tables/${service}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': store.local.get('i18nextLng') ?? 'en',
            },
          },
        );
        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  return {
    isLoading,
    getAuthorityTable,
  };
};
