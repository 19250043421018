import { authenticationManager } from '@/util/authenticationManager';
import { Token } from '@tier4/webauto-auth-core';

/**
 * 認可情報取得
 */
const postAuthorizationAPI = async (): Promise<Token> => {
  const tokenSource = await authenticationManager.getTokenSource();
  const token = await tokenSource.getToken();
  return token;
};

export const getBearerToken = async (): Promise<Token | null> => {
  const res = await postAuthorizationAPI();
  if (res) return res;

  return null;
};
