import { ReleaseNote, useReleaseNotes } from '@/services/documents';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Bottom from './Bottom';
import DevelopersGuides from './DevelopersGuides';
import HowToStart from './HowToStart';
import { IndexImageWrapper } from './styled';
import Top from './Top';
import UserManuals from './UserManuals';

const WebAutoIndex: React.FC = () => {
  const { i18n } = useTranslation();
  const { getReleaseNotes } = useReleaseNotes();
  const [userManualsNotes, setUserManualsNotes] = useState<ReleaseNote[]>([]);
  const [developerGuidesNotes, setDeveloperGuidesNotes] = useState<
    ReleaseNote[]
  >([]);

  useEffect(() => {
    (async () => {
      const releaseNotesRes = await getReleaseNotes('release-notes/');
      const currentLanguageReleaseNotesData = releaseNotesRes[i18n.language];
      if (
        currentLanguageReleaseNotesData &&
        currentLanguageReleaseNotesData.release_notes.length
      ) {
        setUserManualsNotes(
          currentLanguageReleaseNotesData.release_notes
            .filter(
              (note) =>
                note.categories.includes('User Manuals') ||
                note.categories.includes('User Manual'),
            )
            .slice(0, 6),
        );
        setDeveloperGuidesNotes(
          currentLanguageReleaseNotesData.release_notes
            .filter(
              (note) =>
                note.categories.includes('Developer’s Guides') ||
                note.categories.includes('Developer Guides') ||
                note.categories.includes('Developer Guide'),
            )
            .slice(0, 6),
        );
      }
    })();
  }, [getReleaseNotes, i18n.language]);

  return (
    <>
      <Top />
      <IndexImageWrapper className="top" />
      <HowToStart />
      <UserManuals releaseNotes={userManualsNotes} />
      <IndexImageWrapper className="product" />
      <DevelopersGuides releaseNotes={developerGuidesNotes} />
      <Bottom />
    </>
  );
};

export default WebAutoIndex;
