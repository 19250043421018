import { isBranchPreview } from './appConfig';

// ページのURLの branch パラメータを返す
export const getPageBranchParam = () => {
  const params = new URLSearchParams(window.location.search);
  const branchParam = params.get('branch');
  if (branchParam) {
    return branchParam.replaceAll('/', '__').replaceAll('%2F', '__');
  }
  return null;
};

// ドキュメント API のリクエストにブランチを付与するかどうか
export const getAPIBranchPath = () => {
  if (!isBranchPreview) return '';
  const pageBranchParam = getPageBranchParam();
  if (!pageBranchParam) return '';
  return `/${pageBranchParam}`;
};

// サイト内リンクに branch パラメータを付与
export const getBranchLink = (href: string) => {
  if (!href) return '';
  if (!isBranchPreview) return href;
  const isAnchorLink = href.indexOf('#') === 0;
  const isExternalLink = href.match(/^https?/);
  if (isAnchorLink || isExternalLink) return href;

  const pageBranchParam = getPageBranchParam();
  if (!pageBranchParam) return href;

  const [pathHash, hrefParamString] = href.split('?');
  const hrefParams = new URLSearchParams(hrefParamString);
  const hasHrefBranchParam = !!hrefParams.get('branch');
  if (hasHrefBranchParam) return href;
  hrefParams.set('branch', pageBranchParam);
  const pathname = pathHash.split('#');
  return `${pathname[0]}?${hrefParams.toString()}${
    pathname[1] ? `#${pathname[1]}` : ''
  }`;
};
