import { mq } from '@/breakpoints';
import NotFound from '@/pages/not_found';
import { ReleaseNote, ReleaseNotesData } from '@/services/documents';
import { getBranchLink } from '@/util/branch';
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import Button from '../Button';
import Admonition from '../markdown/Admonition';
import { css } from '@emotion/react';
import { getContentsImagePath } from '@/util/contetsImagePath';

const PER_PAGE_NUM = 8;

type ReleaseNoteList = {
  ja: ReleaseNote[];
  en: ReleaseNote[];
};

type Props = {
  data: ReleaseNotesData;
};

const ReleaseNoteList: React.FC<Props> = ({ data }: Props) => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [onlyOtherLanguage, setOnlyOtherLanguage] = useState(false);

  const releaseNotes = useMemo(() => {
    const releaseNoteList: ReleaseNoteList = {
      ja: [],
      en: [],
    };
    if (data.ja) {
      releaseNoteList.ja = data.ja.release_notes;
    }
    if (data.en) {
      releaseNoteList.en = data.en.release_notes;
    }
    return releaseNoteList;
  }, [data]);

  const pageNo = useMemo(() => {
    const no = Number(searchParams.get('page'));
    if (Number.isNaN(no) || no <= 0) return 1;
    return no;
  }, [searchParams]);

  const priorityLanguageData = useMemo(() => {
    const otherLanguage = i18n.language === 'ja' ? 'en' : 'ja';
    let returnData = releaseNotes[i18n.language as 'ja' | 'en'];
    if (returnData.length === 0) {
      returnData = releaseNotes[otherLanguage];
      setOnlyOtherLanguage(true);
    }
    return returnData;
  }, [releaseNotes, i18n.language]);

  const splitData = useMemo(() => {
    if (priorityLanguageData) {
      const length = pageNo * PER_PAGE_NUM;
      const returnData = priorityLanguageData
        .concat()
        .slice(
          pageNo * PER_PAGE_NUM - PER_PAGE_NUM,
          length > priorityLanguageData.length
            ? priorityLanguageData.length
            : length,
        );
      return returnData;
    }
    return [];
  }, [priorityLanguageData, pageNo]);

  const list = useMemo(() => {
    if (splitData.length === 0) return;
    return splitData.map((note) => {
      const img = getContentsImagePath(note.thumbnail);
      return (
        <li key={note.slug}>
          <Link to={getBranchLink(note.slug.replace('/ja/', '/'))}>
            <div className="note-wrapper">
              <div className="note-contents">
                <div className="note-date">
                  {DateTime.fromISO(note.published_at).toFormat(
                    t('date.format'),
                  )}
                </div>
                <div className="note-info">
                  <p className="note-title">{note.title}</p>
                  <p className="note-description">{note.description}</p>
                  {note.categories.length > 0 && (
                    <ul className="note-categories">
                      {note.categories.map((category) => (
                        <li className="note-category" key={category}>
                          {category}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div
                className="note-thumbnail"
                css={css`
                  background-image: url(${img});
                `}
              />
            </div>
          </Link>
        </li>
      );
    });
  }, [t, splitData]);

  const pagination = useMemo(() => {
    const isNext = priorityLanguageData.length > pageNo * PER_PAGE_NUM;
    const isPrev = pageNo > 1;
    if (!isNext && !isPrev) return;
    return (
      <div className="pagination">
        <div className="pagination-prev-wrapper">
          {isPrev && (
            <Button
              isLink
              href={getBranchLink(
                pageNo - 1 === 1
                  ? location.pathname
                  : `${location.pathname}?page=${pageNo - 1}`,
              )}
            >
              PREV
            </Button>
          )}
        </div>
        <div className="pagination-next-wrapper">
          {isNext && (
            <Button isLink href={`${location.pathname}?page=${pageNo + 1}`}>
              NEXT
            </Button>
          )}
        </div>
      </div>
    );
  }, [pageNo, priorityLanguageData, location.pathname]);

  const isNotFound = useMemo(
    () => releaseNotes.ja.length === 0 && releaseNotes.en.length === 0,
    [releaseNotes.ja, releaseNotes.en],
  );

  if (!data) return null;

  return (
    <>
      {isNotFound ? (
        <NotFound />
      ) : (
        <article className="markdown-body">
          {onlyOtherLanguage && (
            <OtherLangageAttentionWrapper>
              <Admonition
                type="caution"
                title={<Trans i18nKey="entry.other_language_only" />}
              />
            </OtherLangageAttentionWrapper>
          )}
          <h1>Release Notes</h1>
          <ul className="release-notes">{list}</ul>
          {pagination}
        </article>
      )}
    </>
  );
};

export default ReleaseNoteList;

const OtherLangageAttentionWrapper = styled.div`
  margin-bottom: 60px;

  ${mq.md} {
    margin-bottom: 40px;
  }
`;
