import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from '@/breakpoints';
import { IndexContentsWrapper } from '../styled';
import { useTranslation } from 'react-i18next';

const MARQUEE = 'Web.Auto DOCS';

const Top = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <TitleWrapper>
        <Title
          css={css`
            animation: ${marquee} 40s linear infinite;
          `}
        >
          <span>{MARQUEE}</span>
          <span>{MARQUEE}</span>
          <span>{MARQUEE}</span>
          <span>{MARQUEE}</span>
        </Title>
      </TitleWrapper>
      <IndexContentsWrapper>
        {/* <SearchWrapper>
          <SearchInput placeholder="Search for keywords you are interested in" />
          <button />
        </SearchWrapper> */}
        <LeadWrapper>
          <h3>
            <span>Web.Auto</span> DOCS
          </h3>
          <p>{t('webauto.top.top.description')}</p>
        </LeadWrapper>
      </IndexContentsWrapper>
    </Section>
  );
};

export default Top;

const marquee = keyframes`
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
`;

const TitleWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  left: 0;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 172px;
  color: #5cb4c0;
  opacity: 0.04;
  margin: -28px 0 0;
  line-height: 1;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  display: flex;
  transform: translate3d(var(--move-initial), 0, 0);

  span {
    padding: 0 2em;
  }

  ${mq.md} {
    font-size: 80px;
    margin: 0;
  }
`;

const Section = styled.section`
  position: relative;
  padding: 220px 0 70px;

  ${IndexContentsWrapper} {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  ${mq.md} {
    padding: 144px 0 40px;

    ${IndexContentsWrapper} {
      flex-direction: column-reverse;
    }
  }
`;

// const SearchWrapper = styled.div`
//   position: relative;
//   width: 100%;
//   max-width: 600px;
//   padding-right: 40px;

//   button {
//     display: block;
//     background: url(/assets/common/index/search_arrow.svg);
//     width: 12px;
//     height: 12px;
//     position: absolute;
//     top: calc(50% - 6px);
//     right: 64px;
//     border: none;
//   }

//   ${mq.md} {
//     width: 100%;
//     max-width: auto;

//     button {
//       right: 14px;
//     }
//   }
// `;

// const SearchInput = styled.input`
//   background-color: rgba(92, 180, 192, 0.08);
//   border: none;
//   border-radius: 16px;
//   font-size: 14px;
//   width: 100%;
//   box-sizing: border-box;
//   padding: 24px;
//   font-family: 'Inter';
//   line-height: 1;
//   position: relative;

//   &::placeholder {
//     color: #1f2933;
//     opacity: 0.5;
//   }

//   ${mq.md} {
//     width: 100%;
//     display: block;
//     font-size: 12px;
//     padding: 14px;
//   }
// `;

const LeadWrapper = styled.div`
  width: 320px;

  h3 {
    font-size: 36px;
    font-weight: 600;
    margin: 0;
    line-height: 1.2;

    span {
      background: linear-gradient(270deg, #214772 0%, #3098a6 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }
  }

  p {
    margin: 1.5em 0 0;
    font-family: 'Inter';
    font-size: 12px;
  }

  ${mq.md} {
    width: 60%;
    margin: 0 0 40px;

    h3 {
      font-size: 20px;
    }

    p {
      font-size: 10px;
    }
  }
`;
