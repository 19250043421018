import { Theme } from '@emotion/react';

const theme: Theme = {
  color: {
    primary: '#1f2933',
    secondary: '#fcfcfc',
  },
  textColor: {
    primary: '#1F2933',
    secondary: '#9099A0',
  },
};

export default theme;
