import { User } from '@tier4/webauto-auth-frontend-client';
import { atom } from 'recoil';
import store from 'store2';

export const userAtom = atom<User | null>({
  key: 'userAtom',
  default: null,
});

export const savePath = () => {
  const { pathname, search, hash } = window.location;
  const saveSearch = search && search.length > 0 ? search : '';
  const saveHash = hash && hash.length > 0 ? hash : '';
  store.session.set('path', `${pathname}${saveSearch}${saveHash}`);
};
