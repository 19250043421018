type SiteType = 'webauto' | 'pilotauto';

export const isBranchPreview = import.meta.env.VITE_BRANCH_PREVIEW === 'true';
export const getGoogleAnalyticsId = () =>
  import.meta.env.VITE_ENV_TYPE === 'prd'
    ? (import.meta.env.VITE_GOOGLE_ANALYTICS_ID as string)
    : null;
export const isEnableAuth = import.meta.env.VITE_ENABLE_AUTH === 'true';
export const siteType = import.meta.env.VITE_SITE_TYPE as SiteType;
export const siteTtitle = import.meta.env.VITE_SITE_TITLE as string;
export const siteDescription = import.meta.env.VITE_SITE_DESCRIPTION as string;
export const isWebAuto = siteType === 'webauto';
export const isPilotAuto = siteType === 'pilotauto';
export const documentAPIOrigin = `${import.meta.env.VITE_DOCUMENT_API_PROTOCOL}://${
  import.meta.env.VITE_DOCUMENT_API_DOMAIN
}`;
