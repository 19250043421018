import { Outlet } from 'react-router-dom';
import Drawer from '@/components/common/Drawer';
import Footer from '@/components/common/Footer';
import Header from '@/components/common/Header/Header';
import ScrollToTop from '@/components/common/ScrollToTop';

type Props = {
  children?: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Drawer />
      {children ?? <Outlet />}
      <Footer />
    </>
  );
};

export default Layout;
