import { mq } from '@/breakpoints';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { IndexContentsWrapper, IndexImageWrapper } from '../styled';

const MARQUEE = 'Pilot.Auto DOCS';

const Top: React.FC = () => {
  return (
    <Section>
      <IndexContentsWrapper>
        <TitleWrapper>
          <Title>
            <span>Pilot.Auto</span> DOCS
          </Title>
        </TitleWrapper>
        <Lead>
          How to develop <br className="sp" />
          intelligent vehicles
        </Lead>
      </IndexContentsWrapper>
      <IndexImageWrapper>
        <MarqueeTitleWrapper>
          <MarqueeTitle
            css={css`
              animation: ${marquee} 40s linear infinite;
            `}
          >
            <span>{MARQUEE}</span>
            <span>{MARQUEE}</span>
            <span>{MARQUEE}</span>
            <span>{MARQUEE}</span>
          </MarqueeTitle>
        </MarqueeTitleWrapper>
      </IndexImageWrapper>
    </Section>
  );
};

export default Top;

const Section = styled.section`
  margin-top: 196px;

  ${IndexImageWrapper} {
    position: relative;
    background-image: url(/assets/pilotauto/index/image_1.jpg);
    padding-top: 42%;

    ${mq.md} {
      background-image: url(/assets/pilotauto/index/image_1_sp.jpg);
    }
  }
`;

const TitleWrapper = styled.div``;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;

  span {
    background: linear-gradient(225.93deg, #073a86 0%, #2d849a 101.67%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  ${mq.md} {
    font-size: 20px;
  }
`;

const Lead = styled.p`
  margin: 24px 0 48px;
  line-height: 1.4;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Inter';

  ${mq.md} {
    font-family: futura-pt;
    font-size: 14px;
    line-height: 1.2;
    margin: 0 0 28px;
  }
`;

const marquee = keyframes`
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
`;

const MarqueeTitleWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  left: 0;
  top: 246px;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));

  ${mq.md} {
    top: calc(50% - 50px);
  }
`;

const MarqueeTitle = styled.h2`
  font-weight: 600;
  font-size: 120px;
  margin: 0;
  line-height: 1;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  display: flex;
  transform: translate3d(var(--move-initial), 0, 0);
  mix-blend-mode: color-burn;

  span {
    padding: 0 1em;
    background: linear-gradient(190.93deg, #073a86 0%, #2d849a 101.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    mix-blend-mode: normal;
  }

  ${mq.md} {
    font-size: 80px;
    margin: 0;
  }
`;
