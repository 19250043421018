import { documentAPIOrigin, isBranchPreview } from './appConfig';
import { getPageBranchParam } from './branch';

export const getContentsImagePath = (path: string): string => {
  const branchParam = getPageBranchParam();
  const origin =
    isBranchPreview && branchParam
      ? `${documentAPIOrigin}/${branchParam}`
      : documentAPIOrigin;
  return `${origin}${path}`;
};
