import { enTop as pilotAutoEnTop } from './pilotauto/en';
import { enTop as webAutoEnTop } from './webauto/en';
import { Words } from './type';

const en: Words = {
  date: {
    format: 'MMM dd, yyyy',
  },
  entry: {
    other_language_only:
      'Currently, this content can only be displayed in Japanese.',
    footnote_label: 'Footnotes',
    error: {
      unauthentication: 'Please login to your TIER IV Account.',
      unauthorization: 'You do not have permission to view this page.',
    },
    order_navigation: {
      previous: `Prev`,
      next: 'Next',
    },
  },
  pilotauto: {
    top: pilotAutoEnTop,
  },
  webauto: {
    top: webAutoEnTop,
  },
  authority_table: {
    parameter: {
      apply_to: 'APPLY To',
      result: {
        all: 'All ',
        specific: 'Specific ',
        none: '',
      },
    },
  },
};

export default en;
