import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { mq } from '@/breakpoints';
import { sideMenuOpenAtom } from '@/global-states/sideMenu';
import {
  Navigation,
  NavigationItem,
} from '@/services/documents/navigation/types';
import { getBranchLink } from '@/util/branch';
import { useLangPath } from '@/i18n';
import { removeEndSlash } from '@/util/endSlash';

type Props = {
  data: Navigation | null;
  // https://domain/release-notes 以下かどうか
  isReleaseNoteListPage?: boolean;
};

const SideMenu: React.FC<Props> = ({ data, isReleaseNoteListPage }: Props) => {
  const [open, setOpen] = useRecoilState(sideMenuOpenAtom);
  const location = useLocation();
  const langPath = useLangPath();

  const getIsCurrent = useCallback(
    (link?: string) => {
      if (!link) return 0;
      if (
        !isReleaseNoteListPage &&
        location.pathname.includes('/release-notes')
      ) {
        // User Manuals や Developer Guides 内の Release Notes ページ（一覧・詳細）の場合

        // 末尾にスラッシュがある場合削除
        const pathName = removeEndSlash(location.pathname);
        const splitPaths = pathName.split('/');
        // 最後のパス名が release-notes ではない場合詳細ページ
        const isDetailPage = splitPaths.at(-1) !== 'release-notes';
        const targetPathname = isDetailPage
          ? splitPaths.concat().slice(0, -1).join('/')
          : location.pathname;
        return link.includes(targetPathname) ? 1 : 0;
      }

      return location.pathname.replaceAll('/', '') === link.replaceAll('/', '')
        ? 1
        : 0;
    },
    [location.pathname, isReleaseNoteListPage],
  );

  const handleClickBurger = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, [setOpen]);

  const handleClickNestWrapper = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const element = e.currentTarget as HTMLParagraphElement;
      if (!element.classList.contains('nest')) return;
      const nextElement = element.nextElementSibling;
      if (nextElement) {
        const ulElement = nextElement as HTMLUListElement;
        if (ulElement.classList.contains('hide')) {
          ulElement.classList.remove('hide');
          element.classList.add('open');
        } else {
          ulElement.classList.add('hide');
          element.classList.remove('open');
        }
      }
    },
    [],
  );

  const setNestNavigations = useCallback(
    (list?: NavigationItem[]) => {
      if (!list) return;
      return list.map((item, i) => {
        const hasChildren =
          !!item.navigation?.items && !!item.navigation.items.length;
        const isCurrent = getIsCurrent(item.link);
        return (
          <SubListItem
            key={`nest${i}`}
            data-current={isCurrent}
            current={isCurrent}
          >
            {item.link ? (
              <Link
                className={
                  hasChildren
                    ? `nest ${item.navigation?.expand ? 'open' : ''}`
                    : ''
                }
                to={getBranchLink(`${item.link.replace('/ja', '')}`)}
                onClick={handleClickNestWrapper}
              >
                {hasChildren ? <span>{item.name}</span> : item.name}
              </Link>
            ) : (
              <p
                className={
                  hasChildren
                    ? `nest ${item.navigation?.expand ? 'open' : ''}`
                    : ''
                }
                onClick={handleClickNestWrapper}
              >
                {item.name}
              </p>
            )}
            {item.navigation?.items ? (
              <SubList
                className={`nest-menu ${item.navigation.expand ? '' : 'hide'}`}
              >
                {setNestNavigations(item.navigation.items)}
              </SubList>
            ) : null}
          </SubListItem>
        );
      });
    },
    [getIsCurrent, handleClickNestWrapper],
  );

  const setSubNavigation = useCallback(
    (list?: NavigationItem[]) => {
      if (!list) return;
      return list.map((item, i) => {
        const hasChildren =
          !!item.navigation?.items && !!item.navigation.items.length;
        const isCurrent = getIsCurrent(item.link);
        return (
          <RootListItem
            key={`root${i}`}
            data-current={isCurrent}
            current={isCurrent}
          >
            {item.link ? (
              <Link
                className={
                  hasChildren
                    ? `nest ${item.navigation?.expand ? 'open' : ''}`
                    : ''
                }
                to={getBranchLink(`${item.link.replace('/ja', '')}`)}
                onClick={handleClickNestWrapper}
              >
                {hasChildren ? <span>{item.name}</span> : item.name}
              </Link>
            ) : (
              <p
                className={
                  hasChildren
                    ? `nest ${item.navigation?.expand ? 'open' : ''}`
                    : ''
                }
                onClick={handleClickNestWrapper}
              >
                {item.name}
              </p>
            )}
            {item.navigation?.items && (
              <SubList
                className={`sub nest-menu ${
                  item.navigation.expand ? '' : 'hide'
                }`}
              >
                {setNestNavigations(item.navigation.items)}
              </SubList>
            )}
          </RootListItem>
        );
      });
    },
    [setNestNavigations, handleClickNestWrapper, getIsCurrent],
  );

  const setRootNavigation = useCallback(
    (rootList: NavigationItem[]) =>
      rootList.map((item, i) => {
        const hasChildren =
          !!item.navigation?.items && !!item.navigation.items.length;
        const isCurrent = getIsCurrent(item.link);
        return (
          <React.Fragment key={`root${i}`}>
            <Title
              current={isCurrent}
              isReleaseNoteListPage={!!isReleaseNoteListPage}
              className={
                hasChildren
                  ? `nest ${item.navigation?.expand ? 'open' : ''}`
                  : ''
              }
              onClick={handleClickNestWrapper}
            >
              {item.link ? (
                <Link to={getBranchLink(`${item.link.replace('/ja', '')}`)}>
                  {hasChildren ? <span>{item.name}</span> : item.name}
                </Link>
              ) : (
                <span>{item.name}</span>
              )}
            </Title>
            {item.navigation?.items && (
              <RootList
                className={`nest-menu ${item.navigation.expand ? '' : 'hide'}`}
              >
                {setSubNavigation(item.navigation.items)}
              </RootList>
            )}
            {!isReleaseNoteListPage && <Hr />}
          </React.Fragment>
        );
      }),
    [
      setSubNavigation,
      handleClickNestWrapper,
      getIsCurrent,
      isReleaseNoteListPage,
    ],
  );

  const openParentMenu = useCallback((element: HTMLElement | null) => {
    if (!element) return;
    element.classList.remove('hide');
    element.classList.add('open');
    if (element.previousElementSibling) {
      element.previousElementSibling.classList.add('open');
    }
    if (element.parentElement) {
      openParentMenu(element.parentElement.closest('ul.nest-menu'));
    }
  }, []);

  useEffect(() => {
    if (!data) return;
    const currentMenu: HTMLElement | null = document.querySelector(
      '#sidemenu li[data-current="1"',
    );
    if (!currentMenu) return;
    openParentMenu(currentMenu.closest('ul.nest-menu'));
  }, [location.pathname, data, openParentMenu]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname, setOpen]);

  return (
    <Wrapper id="sidemenu" open={open ? 1 : 0}>
      <Sticky>
        <BurgerIcon onClick={handleClickBurger}>
          <span />
          <span />
        </BurgerIcon>
        <MenuWrapper open={open}>
          {isReleaseNoteListPage && (
            <Title current={getIsCurrent(`${langPath}release-notes`)}>
              <Link to={getBranchLink(`${langPath}release-notes`)}>
                RELEASE NOTES
              </Link>
            </Title>
          )}
          {data && setRootNavigation(data.items)}
        </MenuWrapper>
      </Sticky>
    </Wrapper>
  );
};

export default SideMenu;

type MenuWrapperProps = {
  open: boolean;
};

const MenuWrapper = styled.div<MenuWrapperProps>`
  ${mq.md} {
    padding-top: 10px;

    ${({ open }) => {
      if (!open) {
        return css`
          pointer-events: none;
          opacity: 0;
        `;
      }
    }}
  }
`;

type NestWrapperProps = {
  current?: 0 | 1;
  nest?: 0 | 1;
  open?: 0 | 1;
};

const BurgerIcon = styled.div`
  display: none;

  ${mq.md} {
    display: block;
    position: absolute;
    top: 1px;
    right: 24px;
    padding: 15px;
    transition: top 200ms;

    span {
      display: block;
      position: relative;

      &::before,
      &::after {
        width: 12px;
        height: 1.5px;
        border-radius: 0.75px;
        background-color: white;
        margin-bottom: 2.5px;
        display: block;
        content: '';
      }

      &:last-of-type {
        &::before {
          margin-bottom: 0;
        }

        &::after {
          display: none;
        }
      }
    }
  }
`;

const Title = styled.p<NestWrapperProps & { isReleaseNoteListPage?: boolean }>`
  line-height: 1.5;
  margin-top: 28px;
  margin-bottom: 0;
  padding-bottom: 4px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.02em;

  ${({ isReleaseNoteListPage }) => {
    if (isReleaseNoteListPage) {
      return css`
        &:nth-of-type(n + 2) {
          margin-top: 0;
        }
      `;
    }
  }}

  a,
  span {
    padding: 8px 16px 8px 32px;
    display: block;
    cursor: pointer;
  }

  a {
    span {
      padding: 0;
    }
  }

  &.nest {
    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &::after {
        content: '';
        display: block;
        width: 6px;
        height: 8px;
        background-repeat: no-repeat;
        background-image: url(/assets/common/sidemenu/icon_arrow_right_white.svg);
        transition: transform 200ms ease-out;
      }
    }
  }

  &.open {
    span {
      &::after {
        transform: rotate(90deg);
      }
    }
  }

  ${({ current }) => {
    if (current) {
      return css`
        a {
          font-weight: 700;
          border-radius: 0 4px 4px 0;
          background: rgba(31, 41, 51, 0.2);
        }
      `;
    }
  }}

  ${mq.md} {
    font-size: 12px;
  }
`;

const RootList = styled.ul`
  font-size: 13px;
  line-height: 1.5;
  list-style: none;
  padding: 0;
  margin: 0;

  &.hide {
    overflow: hidden;
    padding: 0;
    height: 0;
  }
`;

const RootListItem = styled.li<NestWrapperProps>`
  a,
  p {
    padding: 8px 16px 8px 32px;
    border-radius: 0 4px 4px 0;
    display: block;
  }

  p,
  a {
    margin: 0;

    &.nest {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      span {
        padding-right: 10px;
        flex: 1;
      }

      &::after {
        content: '';
        display: block;
        width: 6px;
        height: 8px;
        background-repeat: no-repeat;
        background-image: url(/assets/common/sidemenu/icon_arrow_right_white.svg);
        transition: transform 200ms ease-out;
      }

      &.open {
        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }

  ${({ current }) => {
    if (current) {
      return css`
        > a {
          font-weight: 700;
          background: rgba(31, 41, 51, 0.2);
        }
      `;
    }
  }}
`;

const Hr = styled.hr`
  margin: 16px 16px 0 24px;
  border: none;
  border-top: solid 1px rgba(255, 255, 255, 0.16);

  ${mq.md} {
    margin-bottom: 24px;
  }
`;

const SubList = styled.ul`
  padding: 4px 0 0 12px;
  list-style: none;

  &.hide {
    overflow: hidden;
    padding: 0;
    height: 0;
  }

  &.sub {
    margin: 0 4px 0 32px;
    border-left: solid 1px rgba(255, 255, 255, 0.16);
    padding-left: 0;

    .nest-menu {
      li {
        margin-left: 0;
      }
    }
  }
`;

const SubListItem = styled.li<NestWrapperProps>`
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 4px;
  margin-left: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  a,
  p {
    padding: 4px 12px !important;
    border-radius: 4px;
    display: block;
  }

  ${({ current }) => {
    if (current) {
      return css`
        > a {
          font-weight: 700;
          background: rgba(31, 41, 51, 0.2);
        }
      `;
    }
  }}
`;

const Sticky = styled.div`
  position: sticky;
  top: 68px;
  padding: 0 8px 36px 0;
  height: calc(100vh - 68px);
  overflow: auto;

  ${mq.md} {
    position: static;
    top: 0;
    padding: 0;
    height: calc(100vh);
  }
`;

type WrapperProps = {
  open: 0 | 1;
};

const Wrapper = styled.div<WrapperProps>`
  width: 260px;
  color: white;
  box-sizing: border-box;
  font-family: 'Inter';
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    background-attachment: fixed !important;
    width: 280px;
    height: 100%;

    ${mq.md} {
      display: none;
    }
  }

  body[data-type='webauto'] & {
    &::before {
      background: linear-gradient(180deg, #3098a6 0px, #214772 800px);
    }

    ${mq.md} {
      background: linear-gradient(270deg, #214772 0%, #3098a6 100%);
    }
  }

  body[data-type='pilotauto'] & {
    &::before {
      background: linear-gradient(225.93deg, #073a86 0%, #2d849a 101.67%);
    }

    ${mq.md} {
      background: linear-gradient(225.93deg, #073a86 0%, #2d849a 101.67%);
    }
  }

  a {
    color: white;
    text-decoration: none;
  }

  ${mq.md} {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    position: sticky;
    top: 0;
    z-index: 10;
    transition:
      padding 200ms,
      height 200ms;
    height: 42px;
    overflow: hidden;

    ${({ open }) => {
      if (open) {
        return css`
          padding-top: 24px;
          height: 100vh;

          ${BurgerIcon} {
            top: 11px;
          }
        `;
      }
    }}
  }
`;
