import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { useMemo } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import en from './locales/en';
import ja from './locales/ja';

const detectOptions = {
  order: ['path', 'localStorage', 'navigator'],
  lookupFromPathIndex: 0,
};

export const i18nInit = () => {
  i18next
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init(
      {
        resources: {
          en: {
            translation: en,
          },
          ja: {
            translation: ja,
          },
        },
        fallbackLng: 'ja',
        detection: detectOptions,
        interpolation: {
          escapeValue: false,
        },
      },
      () => {
        if (!['ja', 'en'].includes(i18next.language)) {
          i18next.changeLanguage('ja');
        }
      },
    );
};

export const useLangPath = () => {
  const { i18n } = useTranslation();

  const path = useMemo(() => {
    if (i18n.language === 'ja') return '/';
    return '/en/';
  }, [i18n.language]);

  return path;
};
