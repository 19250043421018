import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Layout from '@/components/common/Layout';
import IndexPage from '@/pages/index';
import EntryPage from '@/pages/[entry]';
import NotFoundPage from '@/pages/not_found';
import { useMount, usePrevious } from 'react-use';
import ReactGA from 'react-ga4';
import { getGoogleAnalyticsId, isEnableAuth } from '@/util/appConfig';
import ReleaseNotesPage from '@/pages/release-notes';
import SignInCallback from '@/components/tier4account/SignInCallback';
import SignOutCalback from '@/components/tier4account/SignOutCalback';
import { useSetRecoilState } from 'recoil';
import { userAtom } from './services/t4account/user';
import { authenticationManager } from './util/authenticationManager';

const AppRoutes: React.FC = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const setUser = useSetRecoilState(userAtom);
  const [isLoading, setIsLoading] = useState(isEnableAuth);
  const googleAnalyticsId = useRef(getGoogleAnalyticsId());

  const pathLang = useMemo(
    () => (location.pathname.split('/')[1] === 'en' ? 'en' : 'ja'),
    [location.pathname],
  );
  const prevPathLang = usePrevious(pathLang);

  useMount(() => {
    // Google Analytics
    if (googleAnalyticsId.current) {
      ReactGA.initialize(googleAnalyticsId.current);
    }
  });

  useEffect(() => {
    // Google Analytics
    if (googleAnalyticsId.current) {
      ReactGA.send({
        hitType: 'pageview',
        page: `${location.pathname}${location.search}${location.hash}`,
      });
    }
  }, [location]);

  useEffect(() => {
    if (prevPathLang && pathLang !== prevPathLang) {
      i18n.changeLanguage(pathLang);
    }
    // eslint-disable-next-line
  }, [pathLang, prevPathLang]);

  useEffect(() => {
    document.body.setAttribute('data-lang', i18n.language);
    const { pathname, search, hash } = window.location;
    const langPath = window.location.pathname.split('/')[1];

    // to EN
    if (i18n.language === 'en') {
      if (langPath === 'en') return;
      navigate(`/en${pathname}${search}${hash}`);
      return;
    }
    // to JA
    if (i18n.language === 'ja') {
      if (langPath !== 'en') return;
      navigate(`${pathname.replace('/en/', '/')}${search}${hash}`);
      return;
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    if (!isEnableAuth) return;
    (async () => {
      let u = await authenticationManager.userManager.getUser();
      if (!u) {
        try {
          u = await authenticationManager.userManager.signInSilent();
        } catch (err) {
          console.log(err);
        }
      }
      setUser(u);
      setIsLoading(false);
    })();
  }, [setUser]);

  if (isEnableAuth && isLoading) {
    return (
      <Routes>
        <Route path="/login/callback/" element={<SignInCallback />} />
        <Route path="/logout/callback/" element={<SignOutCalback />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {isEnableAuth && (
        <>
          <Route path="/login/callback/" element={<SignInCallback />} />
          <Route path="/logout/callback/" element={<SignOutCalback />} />
        </>
      )}
      <Route path="" element={<Layout />}>
        <Route index element={<IndexPage />} />
        <Route path="release-notes/*" element={<ReleaseNotesPage />}>
          <Route path=":year" element={<ReleaseNotesPage />} />
        </Route>
        <Route path=":page_id/*" element={<EntryPage />} />
      </Route>
      <Route path="en" element={<Layout />}>
        <Route index element={<IndexPage />} />
        <Route path="release-notes/*" element={<ReleaseNotesPage />}>
          <Route path=":year" element={<ReleaseNotesPage />} />
        </Route>
        <Route path=":page_id/*" element={<EntryPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
