import { mq } from '@/breakpoints';
import type { Navigation, NavigationItem } from '@/services/documents';
import { getBranchLink } from '@/util/branch';
import { removeEndSlash } from '@/util/endSlash';
import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

type Props = {
  navigation: Navigation;
};

const Breadcrumbs: React.FC<Props> = ({ navigation }) => {
  const location = useLocation();

  const currentPagePathName = useMemo(
    () => removeEndSlash(location.pathname),
    [location.pathname],
  );

  const getAncestors = useCallback(
    (
      path: string,
      children: NavigationItem[] | undefined,
      ancestors: NavigationItem[] = [],
    ): NavigationItem[] | undefined => {
      if (!children) return undefined;
      for (const item of children) {
        if (item.link === path) {
          return ancestors.concat(item);
        }
        const found = getAncestors(
          path,
          item.navigation?.items,
          ancestors.concat(item),
        );
        if (found) {
          return found;
        }
      }
      return undefined;
    },
    [],
  );

  const ancestors = useMemo(() => {
    return getAncestors(currentPagePathName, navigation.items);
  }, [getAncestors, currentPagePathName, navigation.items]);

  const getItemName = useCallback((item: NavigationItem) => {
    if (!item.link) return item.name;
    return <Link to={getBranchLink(item.link)}>{item.name}</Link>;
  }, []);

  return (
    <Wrapper>
      {ancestors?.map((item) => <li key={item.name}>{getItemName(item)}</li>)}
    </Wrapper>
  );
};

export default React.memo(Breadcrumbs);

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 30px;
  font-size: 14px;
  list-style: none;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1;
  align-items: flex-start;
  padding-left: 44px;
  text-indent: -20px;
  background: url(/assets/common/common/icon_home.svg) no-repeat;
  background-position: left -1px;
  background-size: 16px;

  ${mq.md} {
    margin-bottom: 20px;
  }

  li {
    vertical-align: top;
    margin: 0 0 10px;

    &::after {
      content: '';
      background: url(/assets/common/common/icon_nav_arrow_right.svg) no-repeat;
      width: 20px;
      height: 20px;
      display: inline-block;
      margin: -3px 24px 0 8px;
    }
    &:last-of-type {
      &::after {
        content: none;
        margin: 0;
      }
    }

    a {
      color: ${({ theme }) => theme.textColor.primary};
      text-decoration: none;
    }
  }
`;
