import styled from '@emotion/styled';
import { mq } from '@/breakpoints';
import Button from '@/components/common/Button';
import { IndexContentsWrapper } from '../styled';
import { useLangPath } from '@/i18n';
import { useTranslation } from 'react-i18next';

const HowToStart = () => {
  const langPath = useLangPath();
  const { t } = useTranslation();
  return (
    <Section>
      <IndexContentsWrapper>
        <Title>
          HOW TO <span>START</span>
        </Title>
        <LeadWrapper>
          <LeadInner>
            <p>{t('webauto.top.how_to_start.description')}</p>
            <Button
              isLink
              href={`${langPath}user-manuals/tier-iv-account/quick-start/`}
            >
              Get Started
            </Button>
          </LeadInner>
        </LeadWrapper>
      </IndexContentsWrapper>
    </Section>
  );
};

export default HowToStart;

const Section = styled.div`
  background-color: #f8f9fc;

  ${IndexContentsWrapper} {
    padding: 200px 0;
  }

  ${mq.md} {
    ${IndexContentsWrapper} {
      padding: 80px 0;
    }
  }
`;

const Title = styled.h2`
  margin: 0;
  line-height: 1;
  font-weight: 600;

  span {
    background: linear-gradient(270deg, #214772 0%, #3098a6 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 12px;
    background: linear-gradient(270deg, #214772 0%, #3098a6 100%);
  }

  ${mq.md} {
    font-size: 20px;
  }
`;

const LeadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${mq.md} {
    justify-content: flex-start;
  }
`;

const LeadInner = styled.div`
  width: 320px;
  font-family: 'Inter';
  font-size: 14px;

  p {
    margin: 1em 0 3em;
  }

  ${mq.md} {
    width: auto;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    p {
      padding-right: 30%;
      margin: 2em 0;
    }
  }
`;
