import { jaTop as pilotAutoJaTop } from './pilotauto/ja';
import { jaTop as webaAutoJaTop } from './webauto/ja';
import { Words } from './type';

const ja: Words = {
  date: {
    format: 'yyyy/MM/dd',
  },
  entry: {
    other_language_only: '現在、このコンテンツは英語版のみ表示できます。',
    footnote_label: '脚注',
    error: {
      unauthentication: 'TIER IV Account にログインが必要です',
      unauthorization: '閲覧権限がありません',
    },
    order_navigation: {
      previous: '前へ',
      next: '次へ',
    },
  },
  pilotauto: {
    top: pilotAutoJaTop,
  },
  webauto: {
    top: webaAutoJaTop,
  },
  authority_table: {
    parameter: {
      apply_to: '適用範囲',
      result: {
        all: '全ての',
        specific: '特定の',
        none: '',
      },
    },
  },
};

export default ja;
