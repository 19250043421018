import { WebAutoTop } from './types';

export const enTop: WebAutoTop = {
  top: {
    description:
      "Web.Auto is a cloud-native DevOps platform that supports all aspects of the development, build, use, and operation of autonomous driving systems using IoT and cloud technologies. The service provider can use the Operation Platform (OP) to manage service operations and utilize data, and the developer can use the Development Platform (DP) to accelerate the development and build of autonomous driving systems. Here we provide the User Manuals and Developer's Guide for Web.Auto.",
  },
  how_to_start: {
    description:
      'You can start Web.Auto by creating a TIER IV Account. You can get more details at the following link.',
  },
};
