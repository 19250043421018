import { mq } from '@/breakpoints';
import styled from '@emotion/styled';

export const IndexContentsWrapper = styled.div`
  margin: 0 120px;
  position: relative;

  ${mq.md} {
    margin: 0 20px;
  }
`;

export const IndexImageWrapper = styled.div`
  background-position: center;
  background-size: cover;

  ${mq.md} {
    padding-top: 100% !important;
  }
`;
