export const addEndSlash = (path: string): string => {
  if (!path) return path;
  const endChar = path.slice(-1);
  if (endChar === '/') return path;
  return `${path}/`;
};

export const removeEndSlash = (path: string): string => {
  if (!path) return path;
  const endChar = path.slice(-1);
  if (endChar !== '/') return path;
  return path.slice(0, -1);
};
