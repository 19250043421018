import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import ExternalLinkIcon from '@/assets/icon_external_link.svg?react';
import DownloadIcon from '@/assets/icon_download.svg?react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { getBranchLink } from '@/util/branch';

type ButtonType = 'basic' | 'external_link' | 'download';
type ColorType = 'default' | 'white';

type Props = {
  children: React.ReactNode | string;
  href?: string;
  target?: string;
  buttonType?: ButtonType;
  isLink?: boolean;
  colorType?: ColorType;
  download?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & JSX.IntrinsicElements['button'];

const Button: React.FC<Props> = ({
  buttonType = 'basic',
  isLink = false,
  colorType = 'default',
  download,
  children,
  href,
  target,
  ...rest
}: Props) => {
  const icon = useMemo(() => {
    if (buttonType === 'external_link') return <ExternalLinkIcon />;
    if (buttonType === 'download') return <DownloadIcon />;
    // basic
    return <BasicIcon />;
  }, [buttonType]);

  const element = useMemo(
    () => (
      <Wrapper {...rest} colorType={colorType}>
        <div className="text">{children}</div>
        <IconWrapper>{icon}</IconWrapper>
      </Wrapper>
    ),
    [icon, children, rest, colorType],
  );

  if (href) {
    const branchLink = getBranchLink(href);
    if (download && download.length > 0) {
      return (
        <a
          css={css`
            text-decoration: none;
          `}
          href={branchLink}
          download={download}
        >
          {element}
        </a>
      );
    }
    if (isLink) {
      return (
        <Link
          css={css`
            text-decoration: none;
          `}
          to={branchLink}
        >
          {element}
        </Link>
      );
    }
    return (
      <a
        css={css`
          text-decoration: none;
        `}
        href={getBranchLink(href)}
        target={target}
      >
        {element}
      </a>
    );
  }

  return element;
};

export default Button;

type WrapperProps = {
  colorType: ColorType;
};

const Wrapper = styled.button<WrapperProps>`
  cursor: pointer;
  background: transparent;
  border: solid 2px #1f2933;
  border-radius: 16px;
  display: flex;
  padding: 12px 16px 12px 20px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  transition: color 450ms;

  ${({ colorType }) => {
    if (colorType === 'white') {
      return css`
        border-color: white;
      `;
    }
  }}

  &::before {
    content: '';
    display: block;
    width: 200%;
    height: calc(100% + 4px);
    background: linear-gradient(90deg, #1f2933, #1f2933, #1f293300);
    position: absolute;
    top: -2px;
    left: -1px;
    transform: translateX(-100%);
    transition: transform 450ms;
  }

  .text {
    position: relative;

    ${({ colorType }) => {
      if (colorType === 'white') {
        return css`
          color: white;
        `;
      }
    }}
  }

  &:hover {
    color: white;

    &::before {
      transform: translateX(0);
    }
  }
`;

const IconWrapper = styled.div`
  margin-left: 10px;
  position: relative;
`;

const BasicIcon = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #3098a6;
`;
